import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { useParams } from "react-router";
import { AxiosError } from "axios";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";

type FranchiseFormData = {
  name: string;
};

const createUserFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

interface Franchise {
  name: string;
  radical: string;
  company_id: string;
  unity?: any;
}

export function EditFranchise() {
  const navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [franchise, setFranchise] = useState<Franchise>({} as Franchise);

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    defaultValues: {
      name: franchise.name,
    },
    resolver: yupResolver(createUserFormSchema),
  });

  useEffect(() => {
    api
      .get(`/franchises/${id}`)
      .then((response) => {
        setFranchise(response.data);
        reset(response.data);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
    setLoading(true);
  }, [id, reset, setValue, navigate]);

  const handleEditFranchise: SubmitHandler<FranchiseFormData> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/franchises/${id}`, {
          name: formValue.name,
        });
        navigate("/redes");
        createToast({
          title: "Rede editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar a rede",
          status: "error",
        });
      }
    },
    [navigate, id]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#2b88a1"
          title="Editar Rede"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditFranchise)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
