export function translateMonthDate(month: number) {
  switch (Number(month)) {
    case 1:
      return "Janeiro";
    case 2:
      return "Fevereiro";
    case 3:
      return "Março";
    case 4:
      return "Abril";
    case 5:
      return "Maio";
    case 6:
      return "Junho";
    case 7:
      return "Julho";
    case 8:
      return "Agosto";
    case 9:
      return "Setembro";
    case 10:
      return "Outubro";
    case 11:
      return "Novembro";
    case 12:
      return "Dezembro";
    default:
      return "";
  }
}

export function getMonths(date: Date) {
  const initialDate = date;

  const initialYear = initialDate.getFullYear();
  const initialMonth = initialDate.getMonth() + 1;

  const today = new Date();

  const endYear = today.getFullYear();
  const endMonth = 12;

  const months = [];

  for (let year = initialYear; year <= endYear; year++) {
    if (year === initialYear) {
      for (var month = initialMonth; month <= 12; month++) {
        months.push({
          value: `${year}-${String(month).padStart(2, "0")}`,
          label: `${translateMonthDate(month)} de ${year}`,
        });
      }
    } else {
      if (year === endYear) {
        for (let month = 1; month <= endMonth; month++) {
          months.push({
            value: `${year}-${String(month).padStart(2, "0")}`,
            label: `${translateMonthDate(month)} de ${year}`,
          });
        }
      } else {
        for (let month = 1; month <= 12; month++) {
          months.push({
            value: `${year}-${String(month).padStart(2, "0")}`,
            label: `${translateMonthDate(month)} de ${year}`,
          });
        }
      }
    }
  }

  return months.reverse();
}
