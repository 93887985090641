import { useEffect, useMemo, useState } from "react";
import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { monthDiff } from "../../../utils/formatDate";
import { formatMooney } from "../../../utils/mooney";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { SimpleGrid } from "@chakra-ui/react";
import { Box } from "../../../components/Widget/Box";
import { FaCalendarAlt, FaDollarSign, FaHandshake } from "react-icons/fa";

interface Company {
  id: string;
  name: string;
  value: number;
  status: boolean;
  started_at?: Date;
  total_received: number;
  churn_at?: Date;
  units: number;
}

export function ListCompaniesWithLTV() {
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    api
      .get(`/financeCompanies/ltv`)
      .then((response) => setCompanies(response.data));
  }, []);

  const data = useMemo(() => {
    var total_companies = companies.length;

    const { total_diff, total_value } = companies.reduce(
      (accumulator, currentValue) => {
        accumulator.total_diff += currentValue.started_at
          ? monthDiff(
              new Date(currentValue.started_at),
              currentValue.churn_at
                ? new Date(currentValue.churn_at)
                : new Date()
            )
          : 0;

        accumulator.total_value += Number(currentValue.total_received);

        return accumulator;
      },
      { total_diff: 0, total_value: 0 }
    );

    return {
      total_diff:
        total_companies > 0 ? (total_diff / total_companies).toFixed(1) : 0,
      total_companies,
      total_value:
        total_companies > 0 ? formatMooney(total_value / total_companies) : 0,
    };
  }, [companies]);
  return (
    <>
      <InternalHeader
        title="Empresas - LTV"
        has_filter={false}
      ></InternalHeader>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={1}>
        <Box
          title="Empresas"
          value={data.total_companies}
          icon={FaHandshake}
          color="white"
          bg="cyan.650"
        />
        <Box
          title="Recebimento Total Médio"
          value={data.total_value}
          icon={FaDollarSign}
          color="white"
          bg="green.300"
        />
        <Box
          title="Tempo (m) médio"
          value={data.total_diff}
          icon={FaCalendarAlt}
          color="black"
          bg="orange.450"
        />
      </SimpleGrid>

      <MUITable
        data={companies.map((company) => [
          company.id,
          company.name,
          Number(company.units),
          company.status,
          Number(company.value),
          company.started_at &&
          monthDiff(
            new Date(company.started_at),
            company.churn_at ? new Date(company.churn_at) : new Date()
          ) > 0
            ? company.total_received /
              monthDiff(
                new Date(company.started_at),
                company.churn_at ? new Date(company.churn_at) : new Date()
              )
            : 0,
          Number(company.total_received),
          company.started_at
            ? monthDiff(
                new Date(company.started_at),
                company.churn_at ? new Date(company.churn_at) : new Date()
              )
            : "Não começou",
        ])}
        columns={[
          {
            name: "id",
            options: {
              display: "excluded",
              filter: false,
              sort: false,
            },
          },
          {
            name: "Empresa",
          },
          {
            name: "Unidades",
          },
          {
            name: "active",
            label: "Status",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return dataIndex ? "Ativa" : "Inativa";
              },
            },
          },
          {
            name: "Valor",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return formatMooney(dataIndex);
              },
            },
          },
          {
            name: "Ticket Médio",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return formatMooney(dataIndex);
              },
            },
          },
          {
            name: "Total recebido",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return formatMooney(dataIndex);
              },
            },
          },

          {
            name: "Tempo (m)",
          },
        ]}
      />
    </>
  );
}
