import { Button, Icon, useDisclosure } from "@chakra-ui/react";
import { ElementType } from "react";
import { Link } from "react-router-dom";
import { ModalContentBranchPath } from "../../molecules/ModalContentBranchPath";

interface ModalBillsToPayProps {
  label: string;
  title: string;
  text: string;
  icon?: ElementType;
  firstOptionLink: string;
  secondOptionLink: string;
  firstOptionName: string;
  secondOptionName: string;
}

export function ModalBillsToPay({
  label,
  text,
  title,
  icon,
  firstOptionLink,
  secondOptionLink,
  firstOptionName,
  secondOptionName,
}: ModalBillsToPayProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button
        bg="#2b88a1"
        _hover={{
          background: "#2b88a1",
          opacity: 0.8,
        }}
        _active={{
          background: "#2b88a1",
          opacity: 0.8,
        }}
        color="#fff"
        onClick={onOpen}
      >
        {icon && <Icon as={icon} mr="2" />} {label}
      </Button>
      <ModalContentBranchPath
        text={text}
        title={title}
        onClose={onClose}
        isOpen={isOpen}
      >
        <Link to={firstOptionLink}>
          <Button
            bg="#2b88a1"
            _hover={{
              background: "#2b88a1",
              opacity: 0.8,
            }}
            _active={{
              background: "#2b88a1",
              opacity: 0.8,
            }}
            color="#fff"
          >
            {firstOptionName}
          </Button>
        </Link>
        <Link to={secondOptionLink}>
          <Button
            bg="#fd9000"
            _hover={{
              background: "#fd9000",
              opacity: 0.8,
            }}
            _active={{
              background: "#fd9000",
              opacity: 0.8,
            }}
            color="#fff"
            ml="2"
          >
            {secondOptionName}
          </Button>
        </Link>
      </ModalContentBranchPath>
    </>
  );
}
