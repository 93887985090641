import { ReactNode } from "react";
import { HeaderWithFilter } from "../../organisms/Header/HeaderWithFilter";
import { HeaderWithoutFilter } from "../../organisms/Header/HeaderWithoutFilter";

interface InternalHeaderProps {
  has_filter?: boolean;
  children?: ReactNode;
  title: string;
}

export function InternalHeader({
  children,
  has_filter = true,
  title,
}: InternalHeaderProps) {
  return has_filter ? (
    <HeaderWithFilter title={title}>{children}</HeaderWithFilter>
  ) : (
    <HeaderWithoutFilter title={title}>{children}</HeaderWithoutFilter>
  );
}
