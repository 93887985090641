import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Flex, Select, Stack } from "@chakra-ui/react";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../components/atoms/Input";
import { FormControl } from "../../../components/FormControl";
import { FaCalendarDay } from "react-icons/fa";
interface Company {
  id: string;
  name: string;
}
const createUpsellFormSchema = yup.object().shape({
  company_id: yup
    .string()
    .required("Empresa é obrigatória")
    .uuid("Formato inválido"),
  type: yup.string().required("O tipo é obrigatório"),
  value: yup.string().required("O valor é obrigatório"),
  created_at: yup.string().required("Data é obrigatório"),
});

export function CreateUpsell() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  useEffect(() => {
    api
      .get("/companies/active")
      .then((response) => setCompanies(response.data));
  }, [navigate]);
  const { register, handleSubmit, formState, setValue, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(createUpsellFormSchema),
  });
  const handleCreateUpsell: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/upsells", {
          company_id: formValue.company_id,
          type: formValue.type,
          value: formValue.value.replace(".", "").replace(",", "."),
          created_at: `${formValue.created_at}T10:00:00`,
        });

        navigate("/upsells");
        createToast({
          title: "Upsell cadastrado com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível cadastrar o upsell",
          status: "error",
        });
      }
    },
    [navigate]
  );

  const { errors } = formState;

  return (
    <>
      <Card
        border="#2b88a1"
        title="Cadastrar Upsell"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateUpsell)}
            id="add-form"
          >
            <Stack spacing="4">
              <FormControl
                name="company_id"
                isRequired={true}
                label="Empresas"
                error={errors.company_id}
              >
                <Controller
                  control={control}
                  name="company_id"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione a unidade">
                      {companies.map((company) => (
                        <option value={company.id}>{company.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                name="created_at"
                isRequired={true}
                label="Data"
                error={errors.created_at}
              >
                <Input
                  type="date"
                  {...register("created_at")}
                  icon={FaCalendarDay}
                  isRequired={true}
                />
              </FormControl>
              <FormControl
                name="type"
                isRequired={false}
                label="Tipo"
                error={errors.type}
              >
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione o tipo">
                      <option value="PROGRAMA DE FIDELIDADE">
                        Programa de Fidelidade
                      </option>
                      <option value="ENPS">eNPS</option>
                      <option value="EMAIL MARKETING ASSINATURA">
                        E-mail Marketing Assinatura
                      </option>
                      <option value="EMAIL MARKETING PONTUAL">
                        E-mail Marketing Pontual
                      </option>
                      <option value="EMAIL PESQUISA ASSINATURA">
                        E-mail Pesquisa Assinatura
                      </option>
                      <option value="EMAIL PESQUISA PONTUAL">
                        E-mail Pesquisa Pontual
                      </option>
                    </Select>
                  )}
                />
              </FormControl>
              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
