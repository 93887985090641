import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { InternalHeading } from "../../../atoms/Heading/InternalHeading";
interface HeaderProps {
  title: string;
  children?: ReactNode;
}
export function HeaderWithoutFilter({ title, children }: HeaderProps) {
  return (
    <Flex justify="space-between" wrap="wrap" mb="5" alignItems="center">
      <InternalHeading title={title} />
      <Flex
        flex="1"
        justify="flex-end"
        minW="300px"
        alignItems="center"
        wrap="wrap"
      >
        {children}
      </Flex>
    </Flex>
  );
}
