import { Column } from "@ant-design/plots";

interface ManyColumnTypeProps {
  data: {
    time: string;
    value: number;
    type: string;
  }[];
  color?: string[];
}

function ManyColumnType({
  data,
  color = ["#343a60", "#ff9a0a78"],
}: ManyColumnTypeProps) {
  const config = {
    data,
    isGroup: true,
    xField: "time",
    yField: "value",
    seriesField: "type",
    dodgePadding: 2,
    color,
  };

  return <Column {...config} />;
}

export default ManyColumnType;
