import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useNavigate } from "react-router-dom";
import { formatMooney } from "../../../utils/mooney";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { AddButton } from "../../../components/atoms/Button/AddButton";

interface Reseller {
  id: string;
  type: string;
  value: number;
  payment_status: boolean;
  created_at: Date;
  company: {
    name: string;
  };
  deleted_at?: Date;
}

export function ListUpsells() {
  const navigate = useNavigate();
  const [upsells, setResellers] = useState<Reseller[]>([]);
  useEffect(() => {
    api
      .get("upsells")
      .then((response) => setResellers(response.data))
      .catch((err: AxiosError) => {});
  }, [navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Upsells"
        header={<AddButton link="/upsell/cadastrar" />}
        body={
          <MUITable
            data={upsells.map((upsell) => [
              upsell.id,
              upsell.company.name,
              upsell.type,
              upsell.value,
              upsell.deleted_at
                ? "Cancelado"
                : upsell.payment_status
                ? "Pago"
                : "Pendente",
              upsell.deleted_at,
              upsell.created_at,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Tipo",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Status Pagamento",
              },
              {
                name: "Data cancelamento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: Date) => {
                    if (value) {
                      return formatDateBrIndexWithOutHours(value);
                    }
                    return "";
                  },
                },
              },
              {
                name: "Data cadastro",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        {!rowIndex.rowData[5] && (
                          <DeleteModal
                            onDelete={(id) => {
                              setResellers(
                                upsells.map((upsell) => {
                                  if (upsell.id === id) {
                                    return {
                                      ...upsell,
                                      deleted_at: new Date(),
                                    };
                                  }
                                  return upsell;
                                })
                              );
                            }}
                            id={rowIndex.rowData[0]}
                            route="upsells/soft/"
                            error_message="Não foi possível cancelar o upsell"
                            success_message="Upsell cancelado com sucesso"
                          />
                        )}
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
