import { Button, Icon } from "@chakra-ui/react";
import { shade } from "polished";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

interface AddButtonProps {
  link: string;
}
export function AddButton({ link }: AddButtonProps) {
  return (
    <Button
      as={Link}
      to={link}
      bg="blue.350"
      color="white"
      mr="4px"
      _hover={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
      _active={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
    >
      <Icon as={FaPlusCircle} mr="4px" /> Adicionar
    </Button>
  );
}
