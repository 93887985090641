import { Button, Icon } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

interface ExternalLinkButtonProps {
  link: string;
}

export function ExternalLinkButton({ link }: ExternalLinkButtonProps) {
  return (
    <Button mr={1} as="a" colorScheme="green" target="_blank" href={`${link}`}>
      <Icon as={FaExternalLinkAlt} />
    </Button>
  );
}
