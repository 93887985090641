import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { AxiosError } from "axios";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useNavigate } from "react-router-dom";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { SearchButton } from "../../../components/molecules/TableListButton/SearchButton";
import { ExternalLinkButton } from "../../../components/molecules/TableListButton/ExternalLinkButton";

interface Reseller {
  id: string;
  name: string;
  is_white_label: boolean;
}

export function ListResellers() {
  const navigate = useNavigate();
  const [resellers, setResellers] = useState<Reseller[]>([]);
  useEffect(() => {
    api
      .get("resellers")
      .then((response) => setResellers(response.data))
      .catch((err: AxiosError) => {});
  }, [navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Revendedores"
        header={<AddButton link="/revendedor/cadastrar" />}
        body={
          <MUITable
            data={resellers.map((reseller) => [
              reseller.id,
              reseller.name,
              reseller.is_white_label,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Nome",
              },
              {
                name: "Tipo",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: boolean) => {
                    return value ? "White Label" : "Parceiro";
                  },
                },
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <SearchButton
                          link={`/empresas/revendedor/${rowIndex.rowData[0]}`}
                        />
                        <EditButton
                          link={`/revendedor/editar/${rowIndex.rowData[0]}`}
                        />
                        <ExternalLinkButton
                          link={`${process.env.REACT_APP_WEB_URL}/cadastro/empresa/whiteLabel/${rowIndex.rowData[0]}`}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setResellers(
                              resellers.filter((reseller) => reseller.id !== id)
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="resellers/"
                          error_message="Não foi possível remover o revendedor"
                          success_message="Revendedor removido com sucesso"
                        />
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
