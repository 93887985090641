import {
  FaChartArea,
  FaHandHoldingUsd,
  FaHandshake,
  FaMoneyCheckAlt,
  FaThumbsUp,
} from "react-icons/fa";
import { useAuth } from "../../../../hooks/auth";
import { NavLink } from "../../../molecules/Sidebar/NavLink";
import { NavSection } from "../../../molecules/Sidebar/NavSection";

export function CompaniesSection() {
  const { user } = useAuth();
  return (
    <NavSection title="Empresas" icon={FaHandshake}>
      <NavLink icon={FaChartArea} href="/dashboard/empresas">
        Dashboard
      </NavLink>
      <NavLink icon={FaThumbsUp} href="/empresas">
        Listagem
      </NavLink>
      {user.is_admin <= 1 && (
        <>
          <NavLink icon={FaHandHoldingUsd} href="/upsells">
            Upsell
          </NavLink>
          <NavLink icon={FaMoneyCheckAlt} href="/empresas/ltv">
            LTV
          </NavLink>
        </>
      )}
    </NavSection>
  );
}
