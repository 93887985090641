export function formatPlanType(plan: string) {
  switch (plan) {
    case "MONTHLY": {
      return "Mensal";
    }
    case "QUARTERLY": {
      return "Trimestral";
    }
    case "SEMIANNUALLY": {
      return "Semestral";
    }
    case "YEARLY": {
      return "Anual";
    }
    default: {
      return "";
    }
  }
}
