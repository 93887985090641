import React, { useCallback } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Stack, Switch } from "@chakra-ui/react";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../components/atoms/Input";
import { FormControl } from "../../../components/FormControl";

const createResellerFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  company_id: yup.mixed().nullable(),
  value: yup.string().required("O valor é obrigatório"),
  is_white_label: yup.boolean().notRequired(),
  asaas_id: yup.string().notRequired(),
});

export function CreateReseller() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createResellerFormSchema),
  });
  const handleCreateReseller: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/resellers", {
          name: formValue.name,
          value: formValue.value.replace(".", "").replace(",", "."),
          is_white_label: formValue.is_white_label,
          asaas_id: formValue.asaas_id,
          responsible_name: formValue.responsible_name,
          responsible_email: formValue.responsible_email,
          responsible_phone: formValue.responsible_phone,
        });

        navigate("/revendedores");
        createToast({
          title: "Revendedor cadastrada com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível cadastrar a Revendedor",
          status: "error",
        });
      }
    },
    [navigate]
  );

  const { errors } = formState;

  return (
    <>
      <Card
        border="#2b88a1"
        title="Cadastrar Revendedor"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateReseller)}
            id="add-form"
          >
            <Stack spacing="4">
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
              <FormControl
                name=""
                label="White Label"
                error={errors.is_white_label}
              >
                <Switch size="lg" {...register("is_white_label")} />
              </FormControl>
              <Input
                label="ASAAS ID"
                isRequired={false}
                error={errors.asaas_id}
                {...register("asaas_id")}
              />
              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
              <Input
                label="Nome completo do responsável"
                isRequired={false}
                error={errors.responsible_name}
                {...register("responsible_name")}
              />
              <Input
                label={`E-mail`}
                isRequired={false}
                type="email"
                {...register("responsible_email")}
              />

              <Input
                label={`Celular`}
                isRequired={false}
                error={errors.responsible_phone}
                {...register("responsible_phone")}
                maxLength={15}
                onChange={(e) => {
                  var value = e.target.value;
                  value = value.replace(/\D/g, "");
                  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
                  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
                  e.target.value = value;
                  setValue("responsible_phone", value);
                }}
              />
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
