import { Heading, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaBoxes, FaDatabase, FaHandshake, FaHockeyPuck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import ColumnChart from "../../../components/Charts/Column";
import { FilterStatus } from "../../../components/templates/Filters/Status";
import { FilterYear } from "../../../components/templates/Filters/Year";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { Box } from "../../../components/Widget/Box";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";

interface Chart {
  box: {
    totalCompanies: number;
    units: number;
    total: number;
    ticket_company: number;
  };
  cities: {
    type: string;
    value: number;
  }[];
  states: {
    type: string;
    value: number;
  }[];
  categories: {
    type: string;
    value: number;
  }[];
  times: {
    type: string;
    value: number;
  }[];
}
interface Selected {
  year: string;
  status: string;
}
export function CompaniesDashboard() {
  const [results, setResults] = useState<Chart>({} as Chart);
  const navigate = useNavigate();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    year: query.get("year") ?? "",
    status: query.get("status") ?? "",
  });
  useEffect(() => {
    api
      .get(
        `/dashboards/companies?year=${selected.year}&status=${selected.status}`
      )
      .then((response) => setResults(response.data));
  }, [selected]);
  return (
    <>
      <InternalHeader title="Empresas Dashboard" has_filter={true}>
        <FilterYear
          onChange={(year) => {
            if (year) {
              setSelected({
                ...selected,
                year: year.value,
              });
              navigate(`/dashboard/empresas?year=${year.value}`);
            } else {
              setSelected({
                ...selected,
                year: "",
              });
              navigate(`/dashboard/empresas?year=`);
            }
          }}
        />
        <FilterStatus
          onChange={(status) => {
            setSelected({
              ...selected,
              status: status.value,
            });
            navigate(
              `/dashboard/empresas?status=${status.value}&year=${selected.year}`
            );
          }}
        />
      </InternalHeader>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
        {results.box && (
          <>
            <Box
              title="Empresas"
              value={results.box.totalCompanies ?? 0}
              icon={FaHandshake}
              color="white"
              bg="cyan.650"
            />
            <Box
              title="Unidades"
              value={results.box.units ?? 0}
              icon={FaBoxes}
              color="black"
              bg="yellow.450"
            />
            <Box
              title="Estados"
              value={results.states.length}
              icon={FaHockeyPuck}
              color="white"
              bg="green.500"
            />
            <Box
              title="Cidades"
              value={results.cities.length}
              icon={FaDatabase}
              color="white"
              bg="orange.300"
            />
          </>
        )}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1 }} spacing={2}>
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Tempo ( meses )
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.times ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Setor
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.categories ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Estado
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.states ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Cidades
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.cities ?? []}
              />
            </>
          }
        />
      </SimpleGrid>
    </>
  );
}
