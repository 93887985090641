import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultipleLine } from "../../../components/Charts/MultipleLine";
import { FilterYear } from "../../../components/templates/Filters/Year";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";
import {
  Tabs,
  Icon,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { FaChartBar, FaChartLine } from "react-icons/fa";
import ColumnChart from "../../../components/Charts/Column";

interface Evolution {
  name: string;
  type: string;
  value: number;
}
interface Selected {
  year: string;
}
export function MrrEvolutionDashboard() {
  const [result, setResult] = useState<Evolution[]>([]);
  const navigate = useNavigate();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    year: query.get("year") ?? String(new Date().getFullYear()),
  });
  useEffect(() => {
    api
      .get(`/dashboards/evolution/mrr?year=${selected.year}`)
      .then((response) => setResult(response.data));
  }, [selected.year]);
  return (
    <>
      <InternalHeader title="Evolução MRR" has_filter={true}>
        <FilterYear
          onChange={(year) => {
            if (year) {
              setSelected({
                ...selected,
                year: year.value,
              });
              navigate(`/dashboard/evolucao/mrr?year=${year.value}`);
            } else {
              setSelected({
                ...selected,
                year: "",
              });
              navigate(`/dashboard/evolucao/mrr?year=`);
            }
          }}
        />
      </InternalHeader>

      <Tabs
        borderTop="3px solid #2b88a1"
        pt={1}
        isLazy={true}
        align="center"
        size="md"
        variant="enclosed"
        w="100%"
        bg="white"
        shadow="md"
        mb={2}
        borderRadius="0.25rem"
      >
        <TabList>
          <Tab
            _focus={{
              outline: "none",
            }}
          >
            <Icon as={FaChartLine} />
          </Tab>
          <Tab
            _focus={{
              outline: "none",
            }}
          >
            <Icon as={FaChartBar} />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MultipleLine data={result} />
          </TabPanel>
          <TabPanel>
            <ColumnChart
              data={result}
              xField="type"
              yField="value"
              optionColors={["#53a846"]}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
