import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useNavigate } from "react-router-dom";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { FinanceCompanyModal } from "../../../components/templates/Modal/FinanceCompanyModal";
import { Icon, Button } from "@chakra-ui/react";
import { FaPlusCircle } from "react-icons/fa";
import { EditModalButton } from "../../../components/molecules/TableListButton/EditModalButton";
import { SearchButton } from "../../../components/molecules/TableListButton/SearchButton";

interface FinanceCompanies {
  id: string;
  name: string;
  finance_id: string;
  email: string;
  phone: string;
  companies: number;
}

interface FinanceCompaniesDatabase {
  id: string;
  name: string;
  finance_id: string;
  email: string;
  phone: string;
  companies: any[];
}

export function ListFinanceCompanies() {
  const navigate = useNavigate();
  const [financeCompanies, setFinanceCompanies] = useState<FinanceCompanies[]>(
    []
  );
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<FinanceCompanies>(
    {} as FinanceCompanies
  );

  useEffect(() => {
    api
      .get("/financeCompanies/companies")
      .then((response) =>
        setFinanceCompanies(
          response.data.map((financeCompany: FinanceCompaniesDatabase) => {
            return {
              ...financeCompany,
              companies: financeCompany.companies.length,
            };
          })
        )
      )
      .catch((err: AxiosError) => {});
  }, [navigate]);
  return (
    <>
      <InternalHeader title="Empresas do Financeiro" has_filter={false}>
        <Button
          ml={1}
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
              finance_id: "",
              email: "",
              phone: "",
              companies: 0,
            });
          }}
          colorScheme="blue"
        >
          <Icon as={FaPlusCircle} mr={1} />
          Adicionar
        </Button>
      </InternalHeader>
      <FinanceCompanyModal
        isOpen={isOpen}
        setIsOpen={(status) => setIsOpen(status)}
        setFinanceCompany={(financeCompany) => {
          if (actionType === "create") {
            setFinanceCompanies([...financeCompanies, financeCompany]);
          } else {
            setFinanceCompanies(
              financeCompanies.map((f) => {
                if (f.id === financeCompany.id) {
                  return financeCompany;
                }
                return f;
              })
            );
          }
        }}
        actionType={actionType}
        defaultValue={defaultValue}
      />
      <Card
        border="#2b88a1"
        body={
          <MUITable
            data={financeCompanies.map((financeCompany) => {
              return {
                id: financeCompany.id,
                name: financeCompany.name,
                finance_id: financeCompany.finance_id,
                email: financeCompany.email,
                phone: financeCompany.phone,
                companies: financeCompany.companies,
              };
            })}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "name",
                label: "Nome",
              },
              {
                name: "finance_id",
                label: "ID Sistema Financeiro",
              },
              {
                name: "phone",
                label: "Telefone",
              },
              {
                name: "email",
                label: "E-mail",
              },
              {
                name: "companies",
                label: "Empresas",
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <SearchButton
                          link={`/empresas/financeiro/${rowIndex.rowData[0]}`}
                        />
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue({
                              id: rowIndex.rowData[0],
                              name: rowIndex.rowData[1],
                              finance_id: rowIndex.rowData[2],
                              phone: rowIndex.rowData[3],
                              email: rowIndex.rowData[4],
                              companies: rowIndex.rowData[5],
                            });
                          }}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setFinanceCompanies(
                              financeCompanies.filter(
                                (financeCompany) => financeCompany.id !== id
                              )
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="financeCompanies/"
                          error_message="Não foi possível remover a empresa do financeiro"
                          success_message="Empresa do financeiro removida com sucesso"
                        />
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
