import {
  FaBoxes,
  FaCog,
  FaCommentsDollar,
  FaDollarSign,
  FaFile,
  FaLink,
  FaTag,
  FaUsers,
} from "react-icons/fa";
import { useAuth } from "../../../../hooks/auth";
import { NavLink } from "../../../molecules/Sidebar/NavLink";
import { NavSection } from "../../../molecules/Sidebar/NavSection";

export function ConfigSection() {
  const { user } = useAuth();
  return (
    <NavSection title="Configurações" icon={FaCog}>
      {user.is_admin === 0 && (
        <>
          <NavLink icon={FaTag} href="/categorias">
            Categorias
          </NavLink>
          <NavLink icon={FaUsers} href="/usuarios">
            Usuários
          </NavLink>
          <NavLink icon={FaBoxes} href="/redes">
            Redes
          </NavLink>
          <NavLink icon={FaCommentsDollar} href="/revendedores">
            Revendedores
          </NavLink>
          <NavLink icon={FaDollarSign} href="/empresas/financeiro">
            Empresas Financeiro
          </NavLink>
          <NavLink icon={FaFile} href="/planos">
            Planos
          </NavLink>
        </>
      )}
      <NavLink icon={FaLink} href="/links/pagamento">
        Links
      </NavLink>
    </NavSection>
  );
}
