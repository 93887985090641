import { forwardRef, ForwardRefRenderFunction } from "react";
import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
} from "@chakra-ui/react";

interface InputProps extends ChakraNumberInputProps {
  name: string;
}
const NumberInputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> =
  ({ name, ...rest }, ref) => {
    return (
      <InputGroup size="md">
        <ChakraNumberInput
          name={name}
          id={name}
          ref={ref}
          w="100%"
          borderColor="gray.400"
          {...rest}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </ChakraNumberInput>
      </InputGroup>
    );
  };

export const NumberInput = forwardRef(NumberInputBase);
