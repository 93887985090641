import { FaHandHolding } from "react-icons/fa";
import { TableListModalButton } from "../../../atoms/Button/TableListModalButton";

interface DownsellModalButtonProps {
  onClick: () => void;
}

export function DownsellModalButton({ onClick }: DownsellModalButtonProps) {
  return (
    <TableListModalButton
      color="white"
      bg="#bdbdbd"
      onClick={onClick}
      icon={FaHandHolding}
    />
  );
}
