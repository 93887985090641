import ReactSelect from "react-select";
import { FilterWIthFormControl } from "../../../organisms/FilterWithFormControl";

interface FilterMonthProps {
  onChange: (month: any, year: any) => void;
  months: object[];
}

export function FilterMonth({ onChange, months }: FilterMonthProps) {
  return (
    <FilterWIthFormControl name="page" label="Mensal">
      <ReactSelect
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        placeholder="Selecione um mês"
        onChange={(select: any) => {
          let date = select.value.split("-");

          onChange(date[1], date[0]);
        }}
        options={months}
      />
    </FilterWIthFormControl>
  );
}
