import { FaChartArea, FaChartBar, FaChartLine } from "react-icons/fa";
import { NavLink } from "../../../molecules/Sidebar/NavLink";
import { NavSection } from "../../../molecules/Sidebar/NavSection";

export function DashboardSection() {
  return (
    <NavSection title="Geral" icon={FaChartLine}>
      <NavLink icon={FaChartBar} href="/dashboard/comparativo/mrr/churn">
        MRR x Churn
      </NavLink>
      <NavLink icon={FaChartLine} href="/dashboard/evolucao/mrr">
        Evolução MRR
      </NavLink>
      <NavLink icon={FaChartArea} href="/dashboard/comparativo/upsell/downsell">
        Upsell x Dowsell
      </NavLink>
    </NavSection>
  );
}
