import React from "react";

import { Routes as ReactRoutes, Route } from "react-router-dom";

import { CashFlowDashboard } from "../pages/Admin/Dashboard/cashFlow";
import { CashierEntriesList } from "../pages/CashierEntriesList";
import { CreatePaymentLink } from "../pages/Admin/PaymentLinks/create";
import { Home } from "../pages/Home";
import { PaymentDashboard } from "../pages/PaymentDashboard";
import { ListPaymentLinks } from "../pages/Admin/PaymentLinks";
import { Admin } from "../templates/Admin";
import { CreateClient } from "../pages/CreateClient";
import { ListCompanies } from "../pages/Admin/Companies";
import { Login } from "../pages/Auth/Login";
import { PrivateRoute } from "./PrivateRoute";
import { EditPaymentLink } from "../pages/Admin/PaymentLinks/edit";
import { ListCategories } from "../pages/Admin/Categories";
import { EditCategory } from "../pages/Admin/Categories/edit";
import { CreateCategory } from "../pages/Admin/Categories/create";
import { ListUsers } from "../pages/Admin/Users";
import { EditUser } from "../pages/Admin/Users/edit";
import { CreateUser } from "../pages/Admin/Users/create";
import { ListFranchises } from "../pages/Admin/Franchises/ index";
import { EditFranchise } from "../pages/Admin/Franchises/edit";
import { CreateFranchise } from "../pages/Admin/Franchises/create";
import { ExpectedVersusReceivedDashboard } from "../pages/Admin/Dashboard";
import { ListChurns } from "../pages/Admin/Churns";
import { EditCompany } from "../pages/Admin/Companies/edit";
import { CreateCompany } from "../pages/Admin/Companies/create";
import { ChurnsDashboard } from "../pages/Admin/Dashboard/churn";
import { RegistrationVersusChurnDashboard } from "../pages/Admin/Dashboard/registrationVersusChurn";
import { DefaultersdDashboard } from "../pages/Admin/Payments/defaulters";
import { CompaniesDashboard } from "../pages/Admin/Dashboard/companies";
import { CreateCashierOutlets } from "../pages/Admin/CashierOutlets/create";
import { CashierOutletsList } from "../pages/Admin/CashierOutlets";
import { EditCashierOutlets } from "../pages/Admin/CashierOutlets/edit";
import { MrrEvolutionDashboard } from "../pages/Admin/Dashboard/evolutionMrr";
import { EditReseller } from "../pages/Admin/Resellers/edit";
import { ListResellers } from "../pages/Admin/Resellers";
import { CreateReseller } from "../pages/Admin/Resellers/create";
import { ListResellerCompanies } from "../pages/Admin/Resellers/show";
import { CreateCashierOutletsOfx } from "../pages/Admin/CashierOutlets/ofx";
import { AdminRoute } from "./AdminRoute";
import { ListUpsells } from "../pages/Admin/Upsells";
import { ListCompaniesWithLTV } from "../pages/Admin/Companies/indexLTV";
import { EditChurn } from "../pages/Admin/Churns/edit";
import { ListTransfers } from "../pages/Admin/Transfers";
import { ListDownsells } from "../pages/Admin/Downsells";
import { CreateUpsell } from "../pages/Admin/Upsells/create";
import { UpsellVersusDownsellDashboard } from "../pages/Admin/Dashboard/upsellVersusDownsell";
import { ShowFranchise } from "../pages/Admin/Franchises/show";
import { ChurnsIndicators } from "../pages/Admin/Churns/indicators";
import { SalesRoute } from "./SalesRoute";
import { CreateClientWhiteLabel } from "../pages/Client/WhiteLabel/create";
import { ListFinanceCompanies } from "../pages/Admin/FinanceCompanies";
import { ListPlans } from "../pages/Admin/Plans";
import { EditPlan } from "../pages/Admin/Plans/edit";
import { CreatePlan } from "../pages/Admin/Plans/create";
import { ListCompaniesByFinanceCompany } from "../pages/Admin/Companies/financeCompanies";

const AdminRoutes = () => (
  <Admin>
    <ReactRoutes>
      <Route
        path="/transferencias"
        element={<AdminRoute element={<ListTransfers />} />}
      />
      <Route
        path="/usuarios"
        element={<AdminRoute element={<ListUsers />} />}
      />
      <Route
        path="/usuario/editar/:id"
        element={<AdminRoute element={<EditUser />} />}
      />
      <Route
        path="/usuario/cadastrar"
        element={<AdminRoute element={<CreateUser />} />}
      />
      <Route
        path="/redes"
        element={<AdminRoute element={<ListFranchises />} />}
      />
      <Route
        path="/rede/editar/:id"
        element={<AdminRoute element={<EditFranchise />} />}
      />
      <Route
        path="/rede/cadastrar"
        element={<AdminRoute element={<CreateFranchise />} />}
      />
      <Route path="/planos" element={<AdminRoute element={<ListPlans />} />} />
      <Route
        path="/plano/editar/:id"
        element={<AdminRoute element={<EditPlan />} />}
      />
      <Route
        path="/plano/cadastrar"
        element={<AdminRoute element={<CreatePlan />} />}
      />
      <Route
        path="/rede/:id"
        element={<AdminRoute element={<ShowFranchise />} />}
      />
      <Route
        path="/categorias"
        element={<AdminRoute element={<ListCategories />} />}
      />
      <Route
        path="/categoria/editar/:id"
        element={<AdminRoute element={<EditCategory />} />}
      />
      <Route
        path="/categoria/cadastrar"
        element={<AdminRoute element={<CreateCategory />} />}
      />

      <Route
        path="/revendedores"
        element={<PrivateRoute element={<ListResellers />} />}
      />
      <Route
        path="/revendedor/editar/:id"
        element={<AdminRoute element={<EditReseller />} />}
      />
      <Route
        path="/revendedor/cadastrar"
        element={<AdminRoute element={<CreateReseller />} />}
      />
      <Route
        path="/pagamento/link/editar/:id"
        element={<SalesRoute element={<EditPaymentLink />} />}
      />
      <Route
        path="/pagamento/link/cadastrar"
        element={<SalesRoute element={<CreatePaymentLink />} />}
      />
      <Route
        path="/dashboard/churns"
        element={<PrivateRoute element={<ChurnsDashboard />} />}
      />
      <Route
        path="/dashboard/analise/mensal"
        element={<AdminRoute element={<PaymentDashboard />} />}
      />
      <Route
        path="/dashboard/caixa"
        element={<AdminRoute element={<CashFlowDashboard />} />}
      />
      <Route
        path="/dashboard/comparativo/upsell/downsell"
        element={<PrivateRoute element={<UpsellVersusDownsellDashboard />} />}
      />
      <Route
        path="/dashboard/comparativo/mrr/churn"
        element={
          <PrivateRoute element={<RegistrationVersusChurnDashboard />} />
        }
      />
      <Route
        path="/dashboard/empresas"
        element={<PrivateRoute element={<CompaniesDashboard />} />}
      />
      <Route
        path="/dashboard/evolucao/mrr"
        element={<PrivateRoute element={<MrrEvolutionDashboard />} />}
      />
      <Route
        path="/inadimplentes"
        element={<AdminRoute element={<DefaultersdDashboard />} />}
      />
      <Route
        path="/dashboard/previsto/recebido"
        element={<AdminRoute element={<ExpectedVersusReceivedDashboard />} />}
      />
      <Route
        path="/cadastro/saidas"
        element={<AdminRoute element={<CreateCashierOutlets />} />}
      />
      <Route
        path="/cadastro/saidas/ofx"
        element={<AdminRoute element={<CreateCashierOutletsOfx />} />}
      />
      <Route
        path="/editar/saida/:id"
        element={<AdminRoute element={<EditCashierOutlets />} />}
      />
      <Route
        path="/listagem/saidas"
        element={<AdminRoute element={<CashierOutletsList />} />}
      />
      <Route
        path="/links/pagamento"
        element={<SalesRoute element={<ListPaymentLinks />} />}
      />
      <Route
        path="/listagem/entradas"
        element={<AdminRoute element={<CashierEntriesList />} />}
      />
      <Route
        path="/churns/indicadores/mensal"
        element={<PrivateRoute element={<ChurnsIndicators />} />}
      />
      <Route
        path="/churns"
        element={<PrivateRoute element={<ListChurns />} />}
      />
      <Route
        path="/churn/editar/:id"
        element={<PrivateRoute element={<EditChurn />} />}
      />
      <Route
        path="/empresas"
        element={<PrivateRoute element={<ListCompanies />} />}
      />
      <Route
        path="/empresas/ltv"
        element={<AdminRoute element={<ListCompaniesWithLTV />} />}
      />
      <Route
        path="/empresas/revendedor/:id"
        element={<PrivateRoute element={<ListResellerCompanies />} />}
      />
      <Route
        path="/empresas/financeiro/:finance_company_id"
        element={<AdminRoute element={<ListCompaniesByFinanceCompany />} />}
      />
      <Route
        path="/empresa/cadastrar"
        element={<AdminRoute element={<CreateCompany />} />}
      />
      <Route
        path="/empresa/editar/:id"
        element={<AdminRoute element={<EditCompany />} />}
      />
      <Route
        path="/upsells"
        element={<AdminRoute element={<ListUpsells />} />}
      />
      <Route
        path="/downsells"
        element={<PrivateRoute element={<ListDownsells />} />}
      />
      <Route
        path="/empresas/financeiro"
        element={<AdminRoute element={<ListFinanceCompanies />} />}
      />
      <Route
        path="/upsell/cadastrar"
        element={<AdminRoute element={<CreateUpsell />} />}
      />
      <Route path="/" element={<PrivateRoute element={<Home />} />} />
    </ReactRoutes>
  </Admin>
);

const Routes: React.FC = () => (
  <>
    <ReactRoutes>
      <Route
        path="/cadastro/empresa/whiteLabel/:reseller_id"
        element={<CreateClientWhiteLabel />}
      />
      <Route path="/cadastro/empresa/:id" element={<CreateClient />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<AdminRoutes />} />
    </ReactRoutes>
  </>
);

export default Routes;
