import { ReactNode, useEffect } from "react";
import { Box as ChakraBox } from "@chakra-ui/react";
import { Sidebar } from "../../components/templates/Sidebar";

import Footer from "../../components/Footer";
import { Menu } from "../../components/Menu";
import { useAuth } from "../../hooks/auth";
interface AdminProps {
  children: ReactNode;
}
export function Admin({ children }: AdminProps) {
  const { signOut, user } = useAuth();
  useEffect(() => {
    window.addEventListener("logout", signOut);
  }, [signOut]);

  return (
    <>
      {user && (
        <>
          <Menu />
          <Sidebar />
        </>
      )}
      <ChakraBox
        minH="calc(100vh - 135px) "
        mt="90px"
        ml={{ base: "0", lg: "64" }}
        pr="15px"
        pl="15px"
      >
        {children}
      </ChakraBox>
      {user && <Footer render={true} />}
    </>
  );
}
