import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { createToast } from "../../../Feedback/Toast";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import { SubmitButton } from "../../../Button/SubmitButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
interface FinanceCompany {
  id: string;
  name: string;
  finance_id: string;
  phone: string;
  email: string;
  companies: number;
}

interface FinanceCompanyModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setFinanceCompany: (financeCompany: FinanceCompany) => void;
  actionType: "edit" | "create";
  defaultValue?: {
    name: string;
    finance_id: string;
    id: string;
    email: string;
    phone: string;
    companies: number;
  };
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  finance_id: yup
    .string()
    .required("ID do sistema financeiro é obrigatório")
    .min(
      3,
      "a empresa do sistema financeiro deve conter no mínimo 3 caracteres"
    ),
  email: yup
    .string()
    .email("Format inválido")
    .required("ID do sistema financeiro é obrigatório")
    .min(
      3,
      "a empresa do sistema financeiro deve conter no mínimo 3 caracteres"
    ),
  phone: yup
    .string()
    .required("Telefone é obrigatório")
    .matches(/\(\d{2}\) \d{5}-\d{4}/gm, "Formato inválido"),
});

export function FinanceCompanyModal({
  isOpen,
  setIsOpen,
  setFinanceCompany,
  defaultValue,
  actionType,
}: FinanceCompanyModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (actionType === "edit" && defaultValue) {
      setValue("name", defaultValue.name);
      setValue("finance_id", defaultValue.finance_id);
      setValue("email", defaultValue.email);
      setValue("phone", defaultValue.phone);
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset]);
  const handleCreateFinanceCompany: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .post("/financeCompanies", {
          name: formValue.name,
          finance_id: formValue.finance_id,
          email: formValue.email,
          phone: formValue.phone,
        })
        .then((response) => {
          createToast({
            status: "success",
            title: "Empresa do financeiro cadastrada com sucesso",
          });
          var financeCompany = response.data;
          setFinanceCompany({
            id: financeCompany.id,
            name: financeCompany.name,
            finance_id: financeCompany.finance_id,
            email: financeCompany.email,
            phone: financeCompany.phone,
            companies: 0,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          createToast({
            status: "error",
            title: "Não foi possível cadastrar a empresa do sistema financeiro",
          });
        });
    },
    [setFinanceCompany, setIsOpen, reset]
  );

  const handleEditFinanceCompany: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/financeCompanies/${defaultValue?.id}`, {
          name: formValue.name,
          finance_id: formValue.finance_id,
          email: formValue.email,
          phone: formValue.phone,
        })
        .then((response) => {
          createToast({
            status: "success",
            title: "Empresa do financeiro editada com sucesso",
          });
          var financeCompany = response.data;
          setFinanceCompany({
            id: financeCompany.id,
            name: financeCompany.name,
            finance_id: financeCompany.finance_id,
            phone: financeCompany.phone,
            email: financeCompany.email,
            companies: defaultValue!.companies,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          createToast({
            status: "error",
            title: "Não foi possível editar a empresa do sistema financeiro",
          });
        });
    },
    [defaultValue, setFinanceCompany, setIsOpen, reset]
  );
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} Empresa do
            financeiro
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditFinanceCompany)
                  : handleSubmit(handleCreateFinanceCompany)
              }
              id="add-form"
            >
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                {...register("name")}
                error={errors.name}
              />
              <Input
                type="text"
                label="ID do sistema financeiro"
                isRequired={true}
                {...register("finance_id")}
                error={errors.finance_id}
              />
              <Input
                label={`E-mail`}
                isRequired={true}
                type="email"
                {...register("email")}
              />
              <Input
                label={`Telefone`}
                isRequired={true}
                error={errors.phone}
                {...register("phone")}
                maxLength={15}
                onChange={(e) => {
                  var value = e.target.value;
                  value = value.replace(/\D/g, "");
                  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
                  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
                  e.target.value = value;
                  setValue("phone", value);
                }}
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              mt="2"
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
