import { ElementType, forwardRef, ForwardRefRenderFunction } from "react";
import {
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightAddon,
} from "@chakra-ui/react";
import { FormControl } from "./FormControl";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: any;
  icon?: ElementType;
  isRequired?: boolean;
  description?: string;
}
const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = undefined,
    icon,
    description,
    isRequired = false,
    ...rest
  },
  ref
) => {
  return (
    <FormControl
      name={name}
      isRequired={isRequired}
      label={label}
      error={error}
      description={description}
    >
      <InputGroup size="md">
        <ChakraInput
          variant="outline"
          name={name}
          id={name}
          ref={ref}
          focusBorderColor="blue.350"
          bg="white"
          border="1px"
          borderColor="gray.400"
          color="black"
          {...rest}
        />
        {!!icon && <InputRightAddon children={<Icon as={icon} />} />}
      </InputGroup>
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
