import { useCallback, useEffect, useState } from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  ModalFooter,
  useDisclosure,
  Modal,
  Button,
  Divider,
} from "@chakra-ui/react";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import {
  formatDateBrIndexWithOutHours,
  monthDiff,
} from "../../../utils/formatDate";
import { createToast } from "../../../components/Feedback/Toast";
import { PowerButtonList } from "../../../components/organisms/PowerButtonList";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { Card } from "../../../components/Card";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { useAuth } from "../../../hooks/auth";
import { CopyButtonTableList } from "../../../components/organisms/CopyButtonTableList";
import { formatMooney } from "../../../utils/mooney";
import { formatPlanType } from "../../../utils/formatPlanType";
import { DownsellModalButton } from "../../../components/molecules/TableListButton/DownsellModalButton";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "../../../components/atoms/Input";
import { FaCalendarDay } from "react-icons/fa";
interface Company {
  id: string;
  name: string;
  started_at?: Date;
  city: string;
  state: string;
  status: boolean;
  value: number;
  churn_at?: Date;
  is_plan: boolean;
  plan_type?: string;
}

interface DateCompanyEdit {
  id: string;
  name: string;
}
const createDownsellFormSchema = yup.object().shape({
  created_at: yup.string().required("Data é obrigatório"),
  value: yup.string().required("Valor é obrigatório"),
});
export function ListCompanies() {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [dateCompanyEdit, setDateCompanyEdit] = useState<DateCompanyEdit>();
  const { register, handleSubmit, formState, setValue, reset } = useForm({
    resolver: yupResolver(createDownsellFormSchema),
  });
  const handleUpdateStatus = useCallback(
    (id) => {
      api.patch(`/companies/status/${id}`).then((response) => {
        setCompanies(
          companies.map((company) => {
            if (company.id === id) {
              company.status = !company.status;
            }
            return company;
          })
        );
        createToast({
          title: "Empresa editada com sucesso!",
          status: "success",
        });
      });
    },
    [companies]
  );

  useEffect(() => {
    api.get(`/companies`).then((response) => setCompanies(response.data));
  }, []);

  const handleCreateDownsell: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post(`/downsells`, {
          created_at: `${formValue.created_at}T10:00:00`,
          value: formValue.value.replace(".", "").replace(",", "."),
          company_id: dateCompanyEdit?.id,
        });

        setCompanies(
          companies.map((company) => {
            if (company.id === dateCompanyEdit?.id) {
              company.value =
                Number(company.value) -
                Number(formValue.value.replace(".", "").replace(",", "."));
            }
            return company;
          })
        );
        reset();

        onClose();

        createToast({
          title: "Downsell cadastrado com sucesso!",
          status: "success",
        });
      } catch (err) {
        onClose();
        createToast({
          title: "Não foi cadastrar o downsell!",
          status: "error",
        });
      }
    },
    [companies, dateCompanyEdit?.id, onClose, reset]
  );
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Downsell - {dateCompanyEdit?.name}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody
            pb={6}
            as="form"
            onSubmit={handleSubmit(handleCreateDownsell)}
            id="update-startedAt"
          >
            <FormControl>
              <Input
                type="date"
                label="Data"
                {...register("created_at")}
                icon={FaCalendarDay}
                isRequired={true}
                error={formState.errors.created_at}
              />
            </FormControl>
            <FormControl>
              <Input
                label="Valor"
                error={formState.errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
            </FormControl>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button onClick={onClose} mr={3} colorScheme="red">
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              type="submit"
              form="update-startedAt"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card
        border="#2b88a1"
        title="Empresas"
        header={
          user.is_admin === 0 ? <AddButton link="/empresa/cadastrar" /> : <></>
        }
        body={
          <MUITable
            data={companies.map((company) => [
              company.id,
              company.name,
              company.city,
              company.state,
              company.is_plan,
              company.plan_type,
              company.started_at,
              company.status,
              company.value,
              company.started_at && company.is_plan
                ? monthDiff(
                    new Date(company.started_at),
                    company.churn_at ? new Date(company.churn_at) : new Date()
                  )
                : "-",
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Cidade",
                options: {
                  display: "none",
                },
              },
              {
                name: "Estado",
                options: {
                  display: "none",
                },
              },
              {
                name: "Plano",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: boolean) => {
                    return value ? "Assinatura" : "Pontual";
                  },
                },
              },
              {
                name: "Modelo do Plano",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: string) => {
                    return value ? formatPlanType(value) : "";
                  },
                },
              },
              {
                name: "Data Inicío",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: Date) => {
                    if (value) {
                      return formatDateBrIndexWithOutHours(value);
                    }
                    return;
                  },
                },
              },
              {
                name: "active",
                label: "Status",
                options: {
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return dataIndex ? "Ativa" : "Inativa";
                  },
                },
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Tempo (m)",
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <CopyButtonTableList
                          item={rowIndex.rowData[0]}
                          label="Copiar ID"
                        />
                        {user.is_admin === 0 && (
                          <>
                            {rowIndex.rowData[6] ? (
                              <DownsellModalButton
                                onClick={() => {
                                  setDateCompanyEdit({
                                    id: rowIndex.rowData[0],
                                    name: rowIndex.rowData[1],
                                  });
                                  onOpen();
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            <PowerButtonList
                              link="/empresas"
                              onClick={async () => {
                                handleUpdateStatus(rowIndex.rowData[0]);
                              }}
                              validCorrectButton={rowIndex.rowData[7]}
                            />
                            <EditButton
                              link={`/empresa/editar/${rowIndex.rowData[0]}`}
                            />
                            <DeleteModal
                              onDelete={(id) => {
                                setCompanies(
                                  companies.filter(
                                    (company) => company.id !== id
                                  )
                                );
                              }}
                              id={rowIndex.rowData[0]}
                              route="companies/"
                              error_message="Não foi possível remover a empresa"
                              success_message="Empresa removida com sucesso"
                            />
                          </>
                        )}
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
