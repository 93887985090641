import { Link, Text } from "@chakra-ui/react";
export function PrivacityText() {
  return (
    <Text
      color="#626262"
      fontSize="14px"
      fontWeight="18px"
      w="100%"
      textAlign="start"
    >
      Concordo com os{" "}
      <Link
        color="blue.400"
        href="https://experienciab2s.com/termos-de-uso/"
        target="_blank
      "
      >
        Termos de Uso
      </Link>{" "}
      e{" "}
      <Link
        color="blue.400"
        href="https://experienciab2s.com/politica-de-privacidade/"
        target="_blank
      "
      >
        Política de Privacidade
      </Link>
    </Text>
  );
}
