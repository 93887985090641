import { Heading, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import DualAxes from "../../../components/Charts/DualAxes";
import { MUITable } from "../../../components/Table/MUITable";
import { FilterYear } from "../../../components/templates/Filters/Year";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { TableAndChartFranchiseDashboards } from "../../../components/templates/TableAndChartFranchiseDashboards";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";
import { formatMooney } from "../../../utils/mooney";

interface BarProps {
  time: string;
  value: number;
  type: string;
}

interface TableProps {
  type: string;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  out: number;
  nov: number;
  dec: number;
  total: number;
}
interface Selected {
  year: string;
}
export function CashFlowDashboard() {
  const [bar, setBar] = useState<BarProps[]>([]);
  const [table, setTable] = useState<TableProps[]>([]);
  const navigate = useNavigate();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    year: query.get("year") ?? String(new Date().getFullYear()),
  });
  useEffect(() => {
    api.get(`/dashboards/cashFlow?year=${selected.year}`).then((response) => {
      setBar(response.data.chart);
      setTable(response.data.table);
    });
  }, [selected.year]);
  return (
    <>
      <InternalHeader title="Fluxo de Caixa" has_filter={true}>
        <FilterYear
          onChange={(year) => {
            if (year) {
              setSelected({
                ...selected,
                year: year.value,
              });
              navigate(`/dashboard/caixa?year=${year.value}`);
            } else {
              setSelected({
                ...selected,
                year: "",
              });
              navigate(`/dashboard/caixa?year=`);
            }
          }}
        />
      </InternalHeader>
      <TableAndChartFranchiseDashboards
        table={
          <MUITable
            data={table.map((data) => [
              data.type,
              data.jan,
              data.feb,
              data.mar,
              data.apr,
              data.may,
              data.jun,
              data.jul,
              data.aug,
              data.sep,
              data.out,
              data.nov,
              data.dec,
              data.total,
            ])}
            columns={[
              {
                name: "Fluxo",
              },
              {
                name: "Jan",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Fev",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Mar",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Abr",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Mai",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Jun",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Jul",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Ago",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Set",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Out",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Nov",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Dez",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Total",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
            ]}
          />
        }
        chart={
          <SimpleGrid columns={{ base: 1 }} spacing={2}>
            <Card
              border="#2b88a1"
              mb="20px"
              body={
                <>
                  <Heading fontSize="20px" textAlign="center">
                    Fluxo de Caixa
                  </Heading>
                  <DualAxes bar={bar} line={[]} />
                </>
              }
            />
          </SimpleGrid>
        }
      />
    </>
  );
}
