import { SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaAngry,
  FaDollarSign,
  FaGrin,
  FaHandHoldingUsd,
} from "react-icons/fa";
import { MUITable } from "../../components/Table/MUITable";
import { FilterMonth } from "../../components/templates/Filters/Month";
import { InternalHeader } from "../../components/templates/InternalHeader";
import { PreLoader } from "../../components/templates/PreLoader";
import { Box } from "../../components/Widget/Box";
import { useQuery } from "../../hooks/query";
import { api } from "../../services/api";
import { formatDateBrIndexWithOutHours } from "../../utils/formatDate";
import { getMonths } from "../../utils/helper";
import { formatMooney } from "../../utils/mooney";
interface Payment {
  valueToReceived: number;
  valueReceived: number;
  valueLate: number;
  valueConfirmed: number;
}

interface Confirmed {
  id: string;
  name: string;
  value: number;
  estimated_date: string;
  client_payment_date: string;
}
interface ToReceived {
  id: string;
  name: string;
  value: number;
  due_date: Date;
}
interface Late {
  id: string;
  name: string;
  value: number;
  due_date: Date;
}

interface Received {
  id: string;
  name: string;
  value: number;
  payment_date: Date;
  due_date: Date;
}

interface Selected {
  month: string | number;
  year: string | number;
}
export function PaymentDashboard() {
  const query = useQuery();
  const [result, setResult] = useState<Payment>({} as Payment);
  const [confirmeds, setConfirmeds] = useState<Confirmed[]>([]);
  const [late, setLate] = useState<Late[]>([]);
  const [toReceived, setToReceived] = useState<ToReceived[]>([]);
  const [received, setReceived] = useState<Received[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<Selected>({
    month: query.get("month") ?? new Date().getMonth() + 1,
    year: query.get("year") ?? new Date().getFullYear(),
  });
  useEffect(() => {
    setLoading(false);

    api
      .get(`/dashboards/payments?month=${selected.month}&year=${selected.year}`)
      .then((response) => {
        setConfirmeds(response.data.confirmed);
        setReceived(response.data.received);
        setLate(response.data.late);
        setToReceived(response.data.toReceived);
        setResult(response.data);
        setLoading(true);
      });
  }, [selected.month, selected.year]);
  return (
    <>
      <InternalHeader title="Pagamentos">
        <FilterMonth
          onChange={(month, year) => {
            setSelected({
              month,
              year,
            });
          }}
          months={getMonths(new Date(2019, 12, 1))}
        />
      </InternalHeader>
      <PreLoader isLoaded={loading} message="">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
          <Box
            title="À receber"
            value={formatMooney(result.valueToReceived)}
            icon={FaHandHoldingUsd}
            color="white"
            bg="blue.400"
          />
          <Box
            title="Recebido"
            value={formatMooney(result.valueReceived)}
            icon={FaGrin}
            color="white"
            bg="green.400"
          />
          <Box
            title="Confirmados"
            value={formatMooney(result.valueConfirmed)}
            icon={FaDollarSign}
            color="black"
            bg="yellow.400"
          />
          <Box
            title="Atrasados"
            value={formatMooney(result.valueLate)}
            icon={FaAngry}
            color="white"
            bg="red.400"
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
          <MUITable
            rowsPerPage={5}
            title="Total à Receber"
            data={toReceived.map((payment) => [
              payment.id,
              payment.name,
              payment.value,
              payment.due_date,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Data Prevista",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
            ]}
          />
          <MUITable
            rowsPerPage={5}
            title="Recebido no mês"
            data={received.map((payment) => [
              payment.id,
              payment.name,
              payment.value,
              payment.payment_date,
              payment.due_date,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Data Pagamento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
              {
                name: "Data Vencimento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
            ]}
          />
          <MUITable
            rowsPerPage={5}
            title="Total Confirmado"
            data={confirmeds.map((defaulter) => [
              defaulter.id,
              defaulter.name,
              defaulter.value,
              defaulter.estimated_date,
              defaulter.client_payment_date,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Data Prevista",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: Date) => {
                    return value ? formatDateBrIndexWithOutHours(value) : "";
                  },
                },
              },
              {
                name: "Data do pagamento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: Date) => {
                    return value ? formatDateBrIndexWithOutHours(value) : "";
                  },
                },
              },
            ]}
          />
          <MUITable
            rowsPerPage={5}
            title="Total Atrasados"
            data={late.map((payment) => [
              payment.id,
              payment.name,
              payment.value,
              payment.due_date,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Data Prevista",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
            ]}
          />
        </SimpleGrid>
      </PreLoader>
    </>
  );
}
