import { Select, SelectProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ISelectProps extends SelectProps {
  children: ReactNode;
}

export function SelectOptions({ children, ...rest }: ISelectProps) {
  return (
    <Select
      focusBorderColor="blue.350"
      bg="white"
      border="1px"
      borderColor="gray.400"
      color="black"
      placeholder="Selecione uma opção"
      {...rest}
    >
      {children}
    </Select>
  );
}
