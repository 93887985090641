import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { Card } from "../../../components/Card";
import { createToast } from "../../../components/Feedback/Toast";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { api } from "../../../services/api";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { FormControl } from "../../../components/FormControl";
import { config } from "../../../services/config";
import { FormControlSelect } from "../../../components/molecules/FormControl/FormControlSelect";
import { FaCalendarDay } from "react-icons/fa";
import { Input } from "../../../components/atoms/Input";
import { MUITable } from "../../../components/Table/MUITable";
import { formatMooney } from "../../../utils/mooney";
import {
  createDate,
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
} from "../../../utils/formatDate";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { ModalAddButton } from "../../../components/molecules/TableListButton/ModalAddButton";

interface OfxFormat {
  date: Date;
  value: number;
  name: string;
  id: number;
}

const createCashierOutletFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  category: yup
    .string()
    .required("Categoria obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

export function CreateCashierOutletsOfx() {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, formState } = useForm({});
  const [id, setId] = useState<number>(-1);

  const [founds, setFounds] = useState<OfxFormat[]>([]);
  const [notFounds, setNotFounds] = useState<OfxFormat[]>([]);
  const handleOfx: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      const data = new FormData();
      data.append("file", formValue.file[0]);

      api
        .post(`/billsToPay/ofx`, data, config)
        .then((response) => {
          setFounds(response.data.found);
          setNotFounds(response.data.not_found);
          createToast({
            title: "OFX adicionado com sucesso!",
            status: "success",
          });
        })
        .catch((err) => {
          createToast({
            title: "Não foi possível adicionar o OFX",
            status: "error",
          });
        });
    },
    []
  );
  const {
    register: registerBillsToPay,
    handleSubmit: handleSubmitBillsToPay,
    formState: formStateBillsToPay,
    control: controlBillsToPay,
    reset: resetBillsToPay,
    setValue: setValueBillsToPay,
  } = useForm({
    resolver: yupResolver(createCashierOutletFormSchema),
  });
  const handleCreateCashierOutlets: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/billsToPay", {
          name: formValue.name,
          category: formValue.category,
          value: formValue.value,
          date: formValue.date,
          billing_type: formValue.billing_type,
          installments: formValue.installments,
        });
        setFounds([
          ...founds,
          {
            date: formValue.date,
            value: Number(formValue.value.replace(",", ".")),
            name: formValue.name,
            id,
          },
        ]);
        resetBillsToPay();
        setNotFounds(
          notFounds.filter((revenueNotFound) => revenueNotFound.id !== id)
        );
        createToast({
          title: "Saída de caixa cadastrada com sucesso!",
          status: "success",
        });
        setIsOpen(false);
      } catch (err) {
        createToast({
          title: "Não foi possível cadastrar a saída",
          status: "error",
        });
      }
    },
    [founds, id, notFounds, resetBillsToPay]
  );
  const { errors } = formState;
  const { errors: errorsBillsToPay } = formStateBillsToPay;
  return (
    <>
      <InternalHeader title="Cadastrar saídas OFX" has_filter={false} />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="4xl"
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar - Despesa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmitBillsToPay(handleCreateCashierOutlets)}
              id="add-bills-to-pay"
            >
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                <Input
                  label="Nome"
                  isRequired={true}
                  {...registerBillsToPay("name")}
                />
                <FormControlSelect
                  name="category"
                  label="Categoria"
                  placeholder="Selecione a categoria"
                  options={[
                    {
                      label: "Compras - Despesa à vista",
                      value: "Compras - Despesa à vista",
                    },
                    {
                      label:
                        "Créditos de liquidação duvidosa - Despesa à vista",
                      value:
                        "Créditos de liquidação duvidosa - Despesa à vista",
                    },
                    {
                      label: "Despesa sem categoria - Despesa à vista",
                      value: "Despesa sem categoria - Despesa à vista",
                    },
                    {
                      label:
                        "Despesas com folha de pagamento - Despesa à vista",
                      value:
                        "Despesas com folha de pagamento - Despesa à vista",
                    },
                    {
                      label: "Despesas com imposto de renda - Despesa à vista",
                      value: "Despesas com imposto de renda - Despesa à vista",
                    },
                    {
                      label: "Despesas de amortização - Despesa à vista",
                      value: "Despesas de amortização - Despesa à vista",
                    },
                    {
                      label: "Despesas de envio e entrega - Despesa à vista",
                      value: "Despesas de envio e entrega - Despesa à vista",
                    },
                    {
                      label: "Despesas de escritório - Despesa à vista",
                      value: "Despesas de escritório - Despesa à vista",
                    },
                    {
                      label: "Despesas de juros - Despesa à vista",
                      value: "Despesas de juros - Despesa à vista",
                    },
                    {
                      label: "Despesas com vendas - Despesa à vista",
                      value: "Despesas com vendas - Despesa à vista",
                    },
                    {
                      label:
                        "Despesas de viagens - despesas gerais e administrativas - Despesa à vista",
                      value:
                        "Despesas de viagens - despesas gerais e administrativas - Despesa à vista",
                    },
                    {
                      label: "Despesas salariais - Despesa à vista",
                      value: "Despesas salariais - Despesa à vista",
                    },
                    {
                      label: "Encargos bancários - Despesa à vista",
                      value: "Encargos bancários - Despesa à vista",
                    },
                    {
                      label:
                        "Honorários advocatícios e profissionais - Despesa à vista",
                      value:
                        "Honorários advocatícios e profissionais - Despesa à vista",
                    },
                    {
                      label: "Honorários e taxas - Despesa à vista",
                      value: "Honorários e taxas - Despesa à vista",
                    },
                    {
                      label:
                        "Materiais de Mensalidades e assinaturas - Despesa à vista",
                      value:
                        "Materiais de Mensalidades e assinaturas - Despesa à vista",
                    },
                    { label: "Outras despesas", value: "Outras despesas" },
                    {
                      label:
                        "Outras despesas gerais e administrativas - Despesa à vista",
                      value:
                        "Outras despesas gerais e administrativas - Despesa à vista",
                    },
                    {
                      label:
                        "Outros tipos de despesas - Despesas de publicidade - Despesa à vista",
                      value:
                        "Outros tipos de despesas - Despesas de publicidade - Despesa à vista",
                    },
                    {
                      label:
                        "Perdas em operações descontinuadas, líquido de imposto - Despesa à",
                      value:
                        "Perdas em operações descontinuadas, líquido de imposto - Despesa à",
                    },
                    {
                      label: "Refeição e entretenimento - Despesa à vista",
                      value: "Refeição e entretenimento - Despesa à vista",
                    },
                    {
                      label:
                        "Remuneração dos administradores - Despesa à vista",
                      value:
                        "Remuneração dos administradores - Despesa à vista",
                    },
                    {
                      label: "Reparos e Manutenção - Despesa à vista",
                      value: "Reparos e Manutenção - Despesa à vista",
                    },
                    {
                      label: "Seguros - Geral - Despesa à vista",
                      value: "Seguros - Geral - Despesa à vista",
                    },
                    {
                      label: "Seguros - Invalidez - Despesa à vista",
                      value: "Seguros - Invalidez - Despesa à vista",
                    },
                    {
                      label: "Seguros - Passivo - Despesa à vista",
                      value: "Seguros - Passivo - Despesa à vista",
                    },
                    {
                      label: "Serviços públicos - Despesa à vista",
                      value: "Serviços públicos - Despesa à vista",
                    },
                    {
                      label: "Suprimentos - Despesa à vista",
                      value: "Suprimentos - Despesa à vista",
                    },
                    {
                      label:
                        "Discrepâncias na conciliação - Outra despesa à vista",
                      value:
                        "Discrepâncias na conciliação - Outra despesa à vista",
                    },
                    {
                      label:
                        "Compensação da folha de pagamento - Outros passivos circulante",
                      value:
                        "Compensação da folha de pagamento - Outros passivos circulante",
                    },
                    {
                      label: "Dividendos a pagar - Outros passivos circulante",
                      value: "Dividendos a pagar - Outros passivos circulante",
                    },
                    {
                      label:
                        "Débito de curto prazo - Outros passivos circulante",
                      value:
                        "Débito de curto prazo - Outros passivos circulante",
                    },
                    {
                      label:
                        "Imposto de renda a pagar - Outros passivos circulante",
                      value:
                        "Imposto de renda a pagar - Outros passivos circulante",
                    },
                    {
                      label:
                        "Obrigações acumuladas - Outros passivos circulante",
                      value:
                        "Obrigações acumuladas - Outros passivos circulante",
                    },
                    {
                      label:
                        "Passivos da folha de pagamento - Outros passivos circulante",
                      value:
                        " Passivos da folha de pagamento - Outros passivos circulante",
                    },
                  ]}
                  control={controlBillsToPay}
                  error={errorsBillsToPay.category}
                />
                <Input
                  label="Valor"
                  error={errorsBillsToPay.value}
                  isRequired={true}
                  {...registerBillsToPay("value")}
                  leftAddon="R$"
                  onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 2) {
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ",$1");
                      e.target.value = value;
                    }
                  }}
                />
                <Input
                  label="Data"
                  type="date"
                  {...registerBillsToPay("date")}
                  icon={FaCalendarDay}
                  isRequired={true}
                />
                <FormControlSelect
                  name="billing_type"
                  placeholder="Selecione a forma de pagamento"
                  label="Forma de Pagamento"
                  options={[
                    {
                      value: "cartao",
                      label: "Cartão",
                    },
                    {
                      value: "boleto",
                      label: "Boleto",
                    },
                    {
                      value: "pix",
                      label: "Pix",
                    },
                  ]}
                  control={controlBillsToPay}
                  error={errorsBillsToPay.billing_type}
                />
              </SimpleGrid>
            </Flex>
          </ModalBody>

          <ModalFooter borderTopColor="gray.300" borderTopWidth="1px" mt={5}>
            <Button
              colorScheme="red"
              mr={3}
              mt="6"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </Button>
            <SubmitButton
              formState={formStateBillsToPay}
              form="add-bills-to-pay"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleOfx)}
            id="add-form"
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
              <FormControl
                label="Ofx"
                isRequired={true}
                name="file"
                error={errors.file}
              >
                <input type="file" {...register("file")} required />
              </FormControl>
            </SimpleGrid>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
      {notFounds.length > 0 && (
        <Card
          mt={2}
          title="Pendetes"
          header={<></>}
          body={
            <MUITable
              data={notFounds.map((notFound) => [
                notFound.id,
                notFound.name,
                notFound.value,
                notFound.date,
              ])}
              columns={[
                {
                  name: "Id",
                  options: {
                    filter: false,
                    display: "none",
                  },
                },
                {
                  name: "Nome",
                },
                {
                  name: "Valor",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: number) => {
                      return formatMooney(value);
                    },
                  },
                },
                {
                  name: "Data",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: string) => {
                      return formatDateBrIndexWithOutHours(value);
                    },
                  },
                },
                {
                  name: "Ações",
                  options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (dataIndex: any, rowIndex: any) => {
                      return (
                        <ButtonListButton>
                          <ModalAddButton
                            onClick={() => {
                              setId(rowIndex.rowData[0]);
                              setIsOpen(true);
                              setValueBillsToPay("name", rowIndex.rowData[1]);
                              setValueBillsToPay(
                                "value",
                                formatMooney(rowIndex.rowData[2])
                                  .replace("R$ ", "")
                                  .replace(".", "")
                              );
                              setValueBillsToPay("category", "");

                              setValueBillsToPay(
                                "date",
                                formatDatabaseWithOutHours(
                                  createDate(rowIndex.rowData[3])
                                )
                              );
                              if (String(rowIndex.rowData[1]).match("PIX")) {
                                setValueBillsToPay("billing_type", "pix");
                              } else {
                                setValueBillsToPay("billing_type", "");
                              }
                            }}
                          />
                        </ButtonListButton>
                      );
                    },
                  },
                },
              ]}
            />
          }
        />
      )}

      {founds.length > 0 && (
        <Card
          mt={2}
          title="Já cadastrados"
          header={<></>}
          body={
            <MUITable
              data={founds.map((found) => [
                found.name,
                found.value,

                found.date,
              ])}
              columns={[
                {
                  name: "Nome",
                },
                {
                  name: "Valor",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: number) => {
                      return formatMooney(value);
                    },
                  },
                },
                {
                  name: "Data",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: string) => {
                      return formatDateBrIndexWithOutHours(value);
                    },
                  },
                },
              ]}
            />
          }
        />
      )}
    </>
  );
}
