import {
  Avatar,
  Flex,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { FaAngleDown, FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Logo from "../../assets/logo192.png";

interface ProfileProps {
  showProfileData?: boolean;
}
export function Profile({ showProfileData = true }: ProfileProps) {
  const { signOut, user } = useAuth();

  return (
    <Box
      zIndex="20"
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-end"}
      flex={1}
    >
      <Menu direction="ltr" closeOnSelect>
        <MenuButton>
          <Flex justify="center" alignItems="center">
            <Flex alignItems="center" zIndex="10">
              {showProfileData && (
                <Box mr="4" textAlign="right">
                  <Text>{user.name}</Text>
                  <Text
                    color="gray.800"
                    fontWeight="bold"
                    fontSize="small"
                  ></Text>
                </Box>
              )}
              <Avatar
                size="md"
                name={user ? user.name : ""}
                src={Logo}
                border="3px solid #adb5bd"
                bg="transparent"
              />
            </Flex>
            <Icon as={FaAngleDown} ml="2" />
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem as={Link} to="/login" onClick={signOut}>
            <Icon as={FaPowerOff} mr="5px" />
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
