import { chakra, Image, Text } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import LogoFalae from "../../../assets/logo_vertical.png";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === "children",
});

interface IBoxLoadingProps {
  message?: string;
}

export function BoxLoading({ message }: IBoxLoadingProps) {
  return (
    <ChakraBox
      animate={{
        scale: [1, 1.3, 1.3, 1, 1],
        rotate: [0, 0, 0, 0, 0],
      }}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      }}
    >
      <Image src={LogoFalae} width="100px" margin="auto" />
      <Text fontSize={18} fontWeight="bold" color="#999" mt="4">
        {message}
      </Text>
    </ChakraBox>
  );
}
