import { useEffect, useState } from "react";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { MUITable } from "../../../components/Table/MUITable";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { formatMooney } from "../../../utils/mooney";

interface Churn {
  id: string;
  name: string;
  value: number;
  started_at: Date;
  churn_at: Date;
  date_diff: number;
  reason: string;
}

export function ListChurns() {
  const { user } = useAuth();
  const [churns, setChurns] = useState<Churn[]>([]);
  useEffect(() => {
    api.get(`/churns`).then((response) => setChurns(response.data));
  }, []);
  return (
    <>
      <InternalHeader title="Churns" has_filter={false} />
      <MUITable
        data={churns.map((churn) => [
          churn.id,
          churn.name,
          churn.value,
          churn.reason,
          churn.started_at,
          churn.churn_at,
          churn.date_diff,
        ])}
        columns={[
          {
            name: "id",
            options: {
              display: "excluded",
              filter: false,
              sort: false,
            },
          },
          {
            name: "Empresa",
          },
          {
            name: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: number) => {
                return formatMooney(value);
              },
            },
          },
          {
            name: "Motivo",
          },
          {
            name: "Data Início",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: Date) => {
                return formatDateBrIndexWithOutHours(value);
              },
            },
          },
          {
            name: "Data Churn",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: Date) => {
                return formatDateBrIndexWithOutHours(value);
              },
            },
          },
          {
            name: "Tempo (M)",
          },
          {
            name: "Ações",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return (
                  <ButtonListButton>
                    {user.is_admin === 0 ? (
                      <>
                        <EditButton
                          link={`/churn/editar/${rowIndex.rowData[0]}`}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setChurns(
                              churns.filter((churn) => churn.id !== id)
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="churns/"
                          error_message="Não foi possível remover o Churn"
                          success_message="Churn removido com sucesso"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </ButtonListButton>
                );
              },
            },
          },
        ]}
      />
    </>
  );
}
