import { Image } from "@chakra-ui/react";
import Logo from "../../../../assets/logo.png";

export function SidebarLogo() {
  return (
    <Image
      src={Logo}
      alt="Logo Experiência Falaê!"
      maxW="178px"
      align="center"
    />
  );
}
