import { Flex, SimpleGrid } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../../components/atoms/Input";
import { Card } from "../../../components/Card";
import { createToast } from "../../../components/Feedback/Toast";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { api } from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { FormControlSelect } from "../../../components/molecules/FormControl/FormControlSelect";
import { FaCalendarDay } from "react-icons/fa";
import { formatMooney } from "../../../utils/mooney";
import { formatDatabaseWithOutHours } from "../../../utils/formatDate";

interface BillToPay {
  name: string;
  category: string;
  value: number;
  date: string;
  billing_type: string;
}
const editCashierOutletFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  category: yup
    .string()
    .required("Categoria obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

export function EditCashierOutlets() {
  const navigate = useNavigate();
  const [billToPay, setBillToPay] = useState<BillToPay>({} as BillToPay);
  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(editCashierOutletFormSchema),
    defaultValues: {
      name: billToPay.name,
      category: billToPay.category,
      value: billToPay.value,
      date: billToPay.date,
      billing_type: billToPay.billing_type,
    },
  });
  let { id } = useParams();

  useEffect(() => {
    api.get(`/billsToPay/${id}`).then((response) => {
      setBillToPay(response.data);
      reset({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
        date: formatDatabaseWithOutHours(
          new Date(response.data.date.toString().replace("Z", "-03:00"))
        ),
      });
    });
  }, [id, reset]);
  const handleEditCashierOutlets: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/billsToPay/${id}`, {
          name: formValue.name,
          category: formValue.category,
          value: formValue.value,
          date: formValue.date,
          billing_type: formValue.billing_type,
        });

        navigate("/listagem/saidas");
        createToast({
          title: "Saída de caixa editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar a saída",
          status: "error",
        });
      }
    },
    [id, navigate]
  );
  const { errors } = formState;
  return (
    <>
      <InternalHeader title="Editar saída" has_filter={false} />
      <Card
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleEditCashierOutlets)}
            id="add-form"
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
              <Input label="Nome" isRequired={true} {...register("name")} />
              <FormControlSelect
                name="category"
                label="Categoria"
                placeholder="Selecione a categoria"
                options={[
                  {
                    label: "Compras - Despesa à vista",
                    value: "Compras - Despesa à vista",
                  },
                  {
                    label: "Créditos de liquidação duvidosa - Despesa à vista",
                    value: "Créditos de liquidação duvidosa - Despesa à vista",
                  },
                  {
                    label: "Despesa sem categoria - Despesa à vista",
                    value: "Despesa sem categoria - Despesa à vista",
                  },
                  {
                    label: "Despesas com folha de pagamento - Despesa à vista",
                    value: "Despesas com folha de pagamento - Despesa à vista",
                  },
                  {
                    label: "Despesas com imposto de renda - Despesa à vista",
                    value: "Despesas com imposto de renda - Despesa à vista",
                  },
                  {
                    label: "Despesas de amortização - Despesa à vista",
                    value: "Despesas de amortização - Despesa à vista",
                  },
                  {
                    label: "Despesas de envio e entrega - Despesa à vista",
                    value: "Despesas de envio e entrega - Despesa à vista",
                  },
                  {
                    label: "Despesas de escritório - Despesa à vista",
                    value: "Despesas de escritório - Despesa à vista",
                  },
                  {
                    label: "Despesas de juros - Despesa à vista",
                    value: "Despesas de juros - Despesa à vista",
                  },
                  {
                    label: "Despesas com vendas - Despesa à vista",
                    value: "Despesas com vendas - Despesa à vista",
                  },
                  {
                    label:
                      "Despesas de viagens - despesas gerais e administrativas - Despesa à vista",
                    value:
                      "Despesas de viagens - despesas gerais e administrativas - Despesa à vista",
                  },
                  {
                    label: "Despesas salariais - Despesa à vista",
                    value: "Despesas salariais - Despesa à vista",
                  },
                  {
                    label: "Encargos bancários - Despesa à vista",
                    value: "Encargos bancários - Despesa à vista",
                  },
                  {
                    label:
                      "Honorários advocatícios e profissionais - Despesa à vista",
                    value:
                      "Honorários advocatícios e profissionais - Despesa à vista",
                  },
                  {
                    label: "Honorários e taxas - Despesa à vista",
                    value: "Honorários e taxas - Despesa à vista",
                  },
                  {
                    label:
                      "Materiais de Mensalidades e assinaturas - Despesa à vista",
                    value:
                      "Materiais de Mensalidades e assinaturas - Despesa à vista",
                  },
                  { label: "Outras despesas", value: "Outras despesas" },
                  {
                    label:
                      "Outras despesas gerais e administrativas - Despesa à vista",
                    value:
                      "Outras despesas gerais e administrativas - Despesa à vista",
                  },
                  {
                    label:
                      "Outros tipos de despesas - Despesas de publicidade - Despesa à vista",
                    value:
                      "Outros tipos de despesas - Despesas de publicidade - Despesa à vista",
                  },
                  {
                    label:
                      "Perdas em operações descontinuadas, líquido de imposto - Despesa à",
                    value:
                      "Perdas em operações descontinuadas, líquido de imposto - Despesa à",
                  },
                  {
                    label: "Refeição e entretenimento - Despesa à vista",
                    value: "Refeição e entretenimento - Despesa à vista",
                  },
                  {
                    label: "Remuneração dos administradores - Despesa à vista",
                    value: "Remuneração dos administradores - Despesa à vista",
                  },
                  {
                    label: "Reparos e Manutenção - Despesa à vista",
                    value: "Reparos e Manutenção - Despesa à vista",
                  },
                  {
                    label: "Seguros - Geral - Despesa à vista",
                    value: "Seguros - Geral - Despesa à vista",
                  },
                  {
                    label: "Seguros - Invalidez - Despesa à vista",
                    value: "Seguros - Invalidez - Despesa à vista",
                  },
                  {
                    label: "Seguros - Passivo - Despesa à vista",
                    value: "Seguros - Passivo - Despesa à vista",
                  },
                  {
                    label: "Serviços públicos - Despesa à vista",
                    value: "Serviços públicos - Despesa à vista",
                  },
                  {
                    label: "Suprimentos - Despesa à vista",
                    value: "Suprimentos - Despesa à vista",
                  },
                  {
                    label:
                      "Discrepâncias na conciliação - Outra despesa à vista",
                    value:
                      "Discrepâncias na conciliação - Outra despesa à vista",
                  },
                  {
                    label:
                      "Compensação da folha de pagamento - Outros passivos circulante",
                    value:
                      "Compensação da folha de pagamento - Outros passivos circulante",
                  },
                  {
                    label: "Dividendos a pagar - Outros passivos circulante",
                    value: "Dividendos a pagar - Outros passivos circulante",
                  },
                  {
                    label: "Débito de curto prazo - Outros passivos circulante",
                    value: "Débito de curto prazo - Outros passivos circulante",
                  },
                  {
                    label:
                      "Imposto de renda a pagar - Outros passivos circulante",
                    value:
                      "Imposto de renda a pagar - Outros passivos circulante",
                  },
                  {
                    label: "Obrigações acumuladas - Outros passivos circulante",
                    value: "Obrigações acumuladas - Outros passivos circulante",
                  },
                  {
                    label:
                      "Passivos da folha de pagamento - Outros passivos circulante",
                    value:
                      " Passivos da folha de pagamento - Outros passivos circulante",
                  },
                ]}
                control={control}
                error={errors.category}
              />
              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                  }
                }}
              />
              <Input
                label="Data"
                type="date"
                {...register("date")}
                icon={FaCalendarDay}
                isRequired={true}
              />
              <FormControlSelect
                name="billing_type"
                placeholder="Selecione a forma de pagamento"
                label="Forma de Pagamento"
                options={[
                  {
                    value: "cartao",
                    label: "Cartão",
                  },
                  {
                    value: "boleto",
                    label: "Boleto",
                  },
                  {
                    value: "pix",
                    label: "Pix",
                  },
                ]}
                control={control}
                error={errors.billing_type}
              />
            </SimpleGrid>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
