import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface FilterBoxProps {
  children: ReactNode;
}

export function FilterBox({ children }: FilterBoxProps) {
  return (
    <Box minW="300px" m={1} mt={{ base: "5px", md: 0 }}>
      {children}
    </Box>
  );
}
