import { useMemo } from "react";
import ReactSelect from "react-select";
import { FilterWIthFormControl } from "../../../organisms/FilterWithFormControl";

interface FilterMonthProps {
  onChange: (year: any) => void;
}

export function FilterYear({ onChange }: FilterMonthProps) {
  const years = useMemo(() => {
    let result = [];
    for (let index = new Date().getFullYear(); index >= 2020; index--) {
      result.push({
        label: index,
        value: index,
      });
    }
    return result;
  }, []);
  return (
    <FilterWIthFormControl name="year" label="Ano">
      <ReactSelect
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        placeholder="Selecione um ano"
        isClearable={true}
        onChange={(select: any) => {
          onChange(select);
        }}
        options={years}
      />
    </FilterWIthFormControl>
  );
}
