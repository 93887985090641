import {
  FaChartBar,
  FaEye,
  FaHandHolding,
  FaHandshakeAltSlash,
  FaThumbsDown,
} from "react-icons/fa";
import { NavLink } from "../../../molecules/Sidebar/NavLink";
import { NavSection } from "../../../molecules/Sidebar/NavSection";

export function ChurnsSection() {
  return (
    <NavSection title="Churns" icon={FaHandshakeAltSlash}>
      <NavLink icon={FaChartBar} href="/dashboard/churns">
        Dashboard
      </NavLink>
      <NavLink href="churns/indicadores/mensal" icon={FaEye}>
        Indicadores
      </NavLink>
      <NavLink icon={FaThumbsDown} href="/churns">
        Listagem
      </NavLink>
      <NavLink icon={FaHandHolding} href="/downsells">
        Downsells
      </NavLink>
    </NavSection>
  );
}
