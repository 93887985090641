import { useAuth } from "../../../hooks/auth";
import { CashFlowSection } from "../../organisms/Sidebar/CashFlowSection";
import { ChurnsSection } from "../../organisms/Sidebar/ChurnsSection";
import { CompaniesSection } from "../../organisms/Sidebar/CompaniesSection";
import { ConfigSection } from "../../organisms/Sidebar/ConfigSection";
import { DashboardSection } from "../../organisms/Sidebar/DashboardSection";
import { SidebarSectionStructure } from "../../organisms/Sidebar/SidebarSectionStructure";

export function AdminSidebarNav() {
  const { user } = useAuth();

  if (user.is_admin === 6) {
    return <></>;
  }

  return (
    <SidebarSectionStructure>
      <DashboardSection />
      {user.is_admin <= 1 && (
        <>
          <CashFlowSection />
        </>
      )}
      <CompaniesSection />
      <ChurnsSection />
      {user.is_admin <= 2 && <ConfigSection />}
    </SidebarSectionStructure>
  );
}
