import { ElementType } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { InternalLink } from "../../atoms/Link/InternalLink";
interface BoxProps {
  value: number | string;
  title: string;
  bg: string;
  color: string;
  icon: ElementType;
  link?: string;
  onClick?: () => void;
}

export function Box({
  value,
  title,
  bg,
  color,
  icon,
  link,
  onClick,
}: BoxProps) {
  return (
    <>
      {link ? (
        <InternalLink link={link}>
          <Flex
            borderRadius=".25rem"
            bg="white"
            fontSize="2.2rem"
            margin="0 0 10px 0 "
            whiteSpace="nowrap"
            mb="1rem"
            minH="80px"
            p=".5rem"
            w="100%"
            shadow="md"
          >
            <Flex
              borderRadius="50%"
              alignItems="center"
              fontSize="1.875rem"
              justify="center"
              textAlign="center"
              w="70px"
              bg={bg}
            >
              <Icon as={icon} color={color} />
            </Flex>
            <Flex flexDir="column" justify="space-around" flex="1" p="0 10px">
              <Text
                d="block"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontSize="16px"
              >
                {title}
              </Text>
              <Text d="block" fontSize="16px" mt=".25rem" fontWeight="bold">
                {value}
              </Text>
            </Flex>
          </Flex>
        </InternalLink>
      ) : (
        <Flex
          onClick={onClick}
          cursor={onClick ? "pointer" : "unset"}
          borderRadius=".25rem"
          bg="white"
          fontSize="2.2rem"
          margin="0 0 10px 0 "
          whiteSpace="nowrap"
          mb="1rem"
          minH="80px"
          p=".5rem"
          w="100%"
          shadow="md"
        >
          <Flex
            borderRadius="50%"
            alignItems="center"
            fontSize="1.875rem"
            justify="center"
            textAlign="center"
            w="70px"
            bg={bg}
          >
            <Icon as={icon} color={color} />
          </Flex>
          <Flex flexDir="column" justify="space-around" flex="1" p="0 10px">
            <Text
              d="block"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              fontSize="16px"
            >
              {title}
            </Text>
            <Text d="block" fontSize="16px" mt=".25rem" fontWeight="bold">
              {value}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
}
