import {
  FaCalendarCheck,
  FaCashRegister,
  FaChartBar,
  FaCommentDollar,
  FaFrown,
  FaHandHoldingUsd,
  FaMoneyBill,
  FaTasks,
} from "react-icons/fa";
import { NavLink } from "../../../molecules/Sidebar/NavLink";
import { NavSection } from "../../../molecules/Sidebar/NavSection";

export function CashFlowSection() {
  return (
    <NavSection title="Fluxo de Caixa" icon={FaCashRegister}>
      <NavLink icon={FaChartBar} href="/dashboard/caixa">
        Dashboard
      </NavLink>
      <NavLink icon={FaCommentDollar} href="/dashboard/previsto/recebido">
        Previsão x Recebido
      </NavLink>
      <NavLink icon={FaCalendarCheck} href="/dashboard/analise/mensal">
        Análise Mensal
      </NavLink>
      <NavLink icon={FaFrown} href="/inadimplentes">
        Inadimplentes
      </NavLink>
      <NavLink icon={FaMoneyBill} href="/listagem/entradas">
        Entradas
      </NavLink>
      <NavLink icon={FaTasks} href="/listagem/saidas">
        Saidas
      </NavLink>
      <NavLink icon={FaHandHoldingUsd} href="/transferencias">
        Transferência
      </NavLink>
    </NavSection>
  );
}
