import { DualAxes as AntDualAxes } from "@ant-design/plots";

interface DualAxesProps {
  bar: {
    time: string;
    value: number;
    type: string;
  }[];
  line: {
    time: string;
    count: number;
  }[];
}

function DualAxes({ bar, line }: DualAxesProps) {
  const config = {
    data: [bar, line],
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
        columnWidthRatio: 0.4,
        color: ["#dd4445", "#5aaa4d", "#ff9000"],
      },
      {
        geometry: "line",
        color: "#2b88a1",
      },
    ],
  };
  return <AntDualAxes {...config} />;
}

export default DualAxes;
