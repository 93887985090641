import { ReactNode } from "react";
import { Flex, Collapse, useDisclosure, Icon } from "@chakra-ui/react";
import { Card } from "../../../Card";
import { FaFilter } from "react-icons/fa";
import { DinamicSolidButton } from "../../../atoms/Button/DinamicSolidButton";
import { InternalHeading } from "../../../atoms/Heading/InternalHeading";
interface HeaderProps {
  title: string;
  children?: ReactNode;
}
export function HeaderWithFilter({ title, children }: HeaderProps) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Flex justify="space-between" mb="4">
        <InternalHeading title={title} />
        <DinamicSolidButton onClick={onToggle} color="white" bg="#2b88a1">
          Filtros <Icon as={FaFilter} ml={2} />
        </DinamicSolidButton>
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ overflow: "visible" }}>
        <Card
          mb="4"
          w="100%"
          body={
            <>
              <Flex alignItems="center" wrap="wrap" pos="relative">
                {children}
              </Flex>
            </>
          }
        />
      </Collapse>
    </>
  );
}
