import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { AddButton } from "../../../components/atoms/Button/AddButton";
interface User {
  id: string;
  name: string;
  email: string;
}

export function ListUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get("/users")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate]);

  return (
    <>
      <Card
        border="#2b88a1"
        title="Usuários"
        header={<AddButton link="/usuario/cadastrar" />}
        body={
          <MUITable
            data={users.map((user) => [user.id, user.name, user.email])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              { name: "Nome" },
              { name: "E-mail" },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <EditButton
                          link={`/usuario/editar/${rowIndex.rowData[0]}`}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setUsers(users.filter((user) => user.id !== id));
                          }}
                          id={rowIndex.rowData[0]}
                          route="users/"
                          error_message="Não foi possível deletar o usuário"
                          success_message="Usuário apagada com sucesso"
                        />
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
