import { SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaBoxes,
  FaCalendarAlt,
  FaDollarSign,
  FaHandshake,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { InternalHeader } from "../../components/templates/InternalHeader";
import { Box } from "../../components/Widget/Box";
import { api } from "../../services/api";
import { formatMooney } from "../../utils/mooney";

interface Data {
  cashier_asaas: number;
  cashier_falae: number;
  companies: number;
  units: number;
  mrr: number;
  ticket_unity: number;
  ticket_company: number;
  time: number;
}
export function Home() {
  const [data, setData] = useState<Data>({} as Data);
  useEffect(() => {
    api.get("/dashboards/home").then((response) => setData(response.data));
  }, []);
  return (
    <>
      <InternalHeader title={`Home`} has_filter={false}></InternalHeader>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
        <Box
          title="Ticket Médio Marca"
          value={formatMooney(data.ticket_company)}
          icon={FaMoneyBillAlt}
          color="white"
          bg="purple.300"
        />
        <Box
          title="Ticket Médio Unidade"
          value={formatMooney(data.ticket_unity)}
          icon={FaMoneyCheckAlt}
          color="white"
          bg="cyan.650"
        />
        <Box
          title="MRR Total"
          value={formatMooney(data.mrr)}
          icon={FaDollarSign}
          color="white"
          bg="green.300"
          link="/dashboard/evolucao/mrr"
        />
        <Box
          title="Empresas Ativas"
          value={data.companies ?? 0}
          icon={FaHandshake}
          color="white"
          bg="cyan.650"
          link="/empresas"
        />
        <Box
          title="Nº de Unidades"
          value={data.units ?? 0}
          icon={FaBoxes}
          color="black"
          bg="yellow.450"
        />

        <Box
          title="Média Tempo (M)"
          value={`${data.time ?? 0} m`}
          icon={FaCalendarAlt}
          color="black"
          bg="orange.450"
        />
      </SimpleGrid>
    </>
  );
}
