import { Column } from "@ant-design/charts";

interface DataProps {
  type: string;
  value: number;
}
interface ColumnChartProps {
  data: DataProps[];
  yField: string;
  xField: string;
  category?: string;
  optionColors?: string[];
}

interface LegendProps {
  position:
    | "top"
    | "top-left"
    | "top-right"
    | "left"
    | "left-top"
    | "left-bottom"
    | "right"
    | "right-top"
    | "right-bottom"
    | "bottom"
    | "bottom-left"
    | "bottom-right";
}

function ColumnChart({
  data,
  yField,
  xField,
  category,
  optionColors = [
    "#c5d1d6",
    "#1a365d",
    "#BEE3F8",
    "#2a4365",
    "#90CDF4",
    "#2c5282",
    "#63B3ED",
    "#2b6cb0",
    "#4299e1",
    "#3182ce",
    "#c5d1d6",
    "#1a365d",
  ],
}: ColumnChartProps) {
  const legend: LegendProps = {
    position: "top",
  };
  var config = {
    data,
    xField,
    yField,
    category,
    legend,
    seriesField: xField,
    color: function color(_ref: any) {
      var type = _ref.type;
      if (optionColors.length > 1) {
        var position = data.map((element) => element.type).indexOf(type);
        return optionColors[position];
      }
      return optionColors[0];
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
      ],
      style: { textAlign: "center", fill: "black", fontSize: 14 },
    },
  };
  return <Column {...config} />;
}

export default ColumnChart;
