import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { SearchButton } from "../../../components/molecules/TableListButton/SearchButton";
interface Franchise {
  id: string;
  name: string;
}
export function ListFranchises() {
  const navigate = useNavigate();
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  useEffect(() => {
    api
      .get("/franchises")
      .then((response) => setFranchises(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Redes"
        header={<AddButton link="/rede/cadastrar" />}
        body={
          <MUITable
            data={franchises.map((franchise) => [franchise.id, franchise.name])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Nome",
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <EditButton
                          link={`/rede/editar/${rowIndex.rowData[0]}`}
                        />
                        <SearchButton link={`/rede/${rowIndex.rowData[0]}`} />
                        <DeleteModal
                          onDelete={(id) => {
                            setFranchises(
                              franchises.filter(
                                (franchise) => franchise.id !== id
                              )
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="franchises/"
                          error_message="Não foi possível remover a rede"
                          success_message="Rede removida com sucesso"
                        />
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
