import { ElementType, forwardRef, ForwardRefRenderFunction } from "react";
import {
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { FormControl } from "../../FormControl";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  icon?: ElementType;
  error?: any;
  leftAddon?: string;
  isRequired?: boolean;
  description?: string;
}
const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = undefined,
    description,
    isRequired = false,
    leftAddon,
    icon,
    ...rest
  },
  ref
) => {
  return (
    <FormControl
      name={name}
      isRequired={isRequired}
      label={label}
      error={error}
      description={description}
    >
      <InputGroup size="md">
        {leftAddon && <InputLeftAddon children={leftAddon} />}
        {!!icon && <InputLeftAddon children={<Icon as={icon} />} />}
        <ChakraInput
          autocomplete="off"
          variant="outline"
          name={name}
          id={name}
          ref={ref}
          focusBorderColor="blue.350"
          bg="white"
          border="1px"
          borderColor="gray.400"
          color="black"
          {...rest}
        />
      </InputGroup>
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
