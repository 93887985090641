import { useEffect, useState } from "react";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { SimpleGrid } from "@chakra-ui/react";
import { Box } from "../../../components/Widget/Box";
import {
  FaMoneyBillAlt,
  FaDollarSign,
  FaBoxes,
  FaCalendarAlt,
  FaHandshakeAltSlash,
  FaHandHolding,
  FaDatabase,
  FaHockeyPuck,
} from "react-icons/fa";
import { formatMooney } from "../../../utils/mooney";
import { formatPlanType } from "../../../utils/formatPlanType";
import {
  formatDateBrIndexWithOutHours,
  monthDiff,
} from "../../../utils/formatDate";

interface Data {
  units: number;
  time: number;
  mrr: number;
  ticket_avg_unity: number;
  cities: number;
  states: number;
  churn_units: number;
  mrr_churn: number;
  franchise: {
    name: string;
  };
  companies: {
    id: string;
    name: string;
    started_at?: Date;
    city: string;
    state: string;
    status: boolean;
    value: number;
    churn_at?: Date;
    is_plan: boolean;
    plan_type?: string;
  }[];
}

export function ShowFranchise() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<Data>({} as Data);
  useEffect(() => {
    api
      .get(`/dashboards/franchises/${id}`)
      .then((response) => setData(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [id, navigate]);
  return (
    <>
      <InternalHeader
        title={`Rede - ${data?.franchise?.name ?? ""}`}
        has_filter={false}
      />
      <SimpleGrid spacing={2} columns={{ base: 1, md: 2 }}>
        <Box
          title="Nº de Unidades Ativas"
          value={data.units ?? 0}
          icon={FaBoxes}
          color="black"
          bg="yellow.450"
        />
        <Box
          title="Média Tempo"
          value={`${data.time ?? 0} m`}
          icon={FaCalendarAlt}
          color="black"
          bg="orange.450"
        />
        <Box
          title="Estados"
          value={data.states ?? 0}
          icon={FaHockeyPuck}
          color="white"
          bg="green.500"
        />
        <Box
          title="Cidades"
          value={data.cities ?? 0}
          icon={FaDatabase}
          color="white"
          bg="orange.300"
        />
        <Box
          title="Ticket Médio"
          value={formatMooney(data.ticket_avg_unity)}
          icon={FaMoneyBillAlt}
          color="white"
          bg="purple.300"
        />
        <Box
          title="MRR Total"
          value={formatMooney(data.mrr)}
          icon={FaDollarSign}
          color="white"
          bg="green.300"
        />
        <Box
          title="Churn - Unidades"
          value={data.churn_units ?? 0}
          icon={FaHandshakeAltSlash}
          color="white"
          bg="pink.300"
        />
        <Box
          title="Churn - MRR Total"
          value={formatMooney(data.mrr_churn)}
          icon={FaHandHolding}
          color="white"
          bg="red.300"
        />
      </SimpleGrid>
      <MUITable
        data={data?.companies?.map((company) => [
          company.name,
          company.city,
          company.state,
          company.is_plan,
          company.plan_type,
          company.started_at,
          company.status,
          company.value,
          company.started_at && company.is_plan
            ? monthDiff(
                new Date(company.started_at),
                company.churn_at ? new Date(company.churn_at) : new Date()
              )
            : "-",
        ])}
        columns={[
          {
            name: "Empresa",
          },
          {
            name: "Cidade",
          },
          {
            name: "Estado",
          },
          {
            name: "Plano",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: boolean) => {
                return value ? "Assinatura" : "Pontual";
              },
            },
          },
          {
            name: "Modelo do Plano",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: string) => {
                return value ? formatPlanType(value) : "";
              },
            },
          },
          {
            name: "Data Inicío",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: Date) => {
                if (value) {
                  return formatDateBrIndexWithOutHours(value);
                }
                return;
              },
            },
          },
          {
            name: "active",
            label: "Status",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return dataIndex ? "Ativa" : "Inativa";
              },
            },
          },
          {
            name: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: number) => {
                return formatMooney(value);
              },
            },
          },
          {
            name: "Tempo (m)",
          },
        ]}
      />
    </>
  );
}
