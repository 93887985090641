import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultersTabPanel } from "../../../components/templates/DefaultersTabPanel";
import { FilterStatus } from "../../../components/templates/Filters/Status";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { PreLoader } from "../../../components/templates/PreLoader";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";

interface Defaulter {
  companies: {
    company_name: string;
    total_payments: number;
    value_payments: number;
    credit_value: number;
    bank_splip_value: number;
    asaas_id: string;
    due_date: string;
  }[];
  total_payments: number;
  value_payments: number;
  credit_value: number;
  bank_splip_value: number;
  percentage_of_turnover: number;
}

interface Selected {
  status: string;
}

export function DefaultersdDashboard() {
  const query = useQuery();
  const navigate = useNavigate();
  const [zeroMonthDefaulters, setZeroMonthDefaulters] = useState<Defaulter>(
    {} as Defaulter
  );
  const [firstMonthDefaulters, setFirstMonthDefaulters] = useState<Defaulter>(
    {} as Defaulter
  );
  const [secondMonthDefaulters, setSecondMonthDefaulters] = useState<Defaulter>(
    {} as Defaulter
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState<Selected>({
    status: query.get("status") ?? "",
  } as Selected);

  useEffect(() => {
    setIsLoaded(false);
    api
      .get(`/dashboards/payments/defaulters?status=${selected.status}`)
      .then((response) => {
        setZeroMonthDefaulters(response.data.zero_month);
        setFirstMonthDefaulters(response.data.first_month);
        setSecondMonthDefaulters(response.data.second_month);
        setIsLoaded(true);
      });
  }, [selected.status]);

  return (
    <>
      <InternalHeader title="Inadimplentes" has_filter={true}>
        <FilterStatus
          onChange={(status) => {
            setSelected({
              ...selected,
              status: status.value,
            });
            navigate(`/inadimplentes?status=${status.value}`);
          }}
        />
      </InternalHeader>
      <PreLoader message="Buscando inadimplentes" isLoaded={isLoaded}>
        <Tabs isFitted>
          <TabList>
            <Tab>
              Não Pagantes{" "}
              <Tag
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                ml={1}
              >
                {zeroMonthDefaulters?.companies?.length ?? 0}
              </Tag>
            </Tab>
            <Tab>
              +1 mês{" "}
              <Tag
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="yellow"
                ml={1}
              >
                {firstMonthDefaulters?.companies?.length ?? 0}
              </Tag>
            </Tab>
            <Tab>
              +2 meses
              <Tag
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="red"
                ml={1}
              >
                {secondMonthDefaulters?.companies?.length ?? 0}
              </Tag>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <DefaultersTabPanel
                defaulters={zeroMonthDefaulters}
                has_mrr={true}
              />
            </TabPanel>
            <TabPanel>
              <DefaultersTabPanel
                defaulters={firstMonthDefaulters}
                has_mrr={false}
              />
            </TabPanel>
            <TabPanel>
              <DefaultersTabPanel
                defaulters={secondMonthDefaulters}
                has_mrr={false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PreLoader>
    </>
  );
}
