import { createStandaloneToast } from "@chakra-ui/react";

interface toastProps {
  title: string;
  status: "info" | "warning" | "success" | "error";
  description?: string;
}

const toast = createStandaloneToast();

export const createToast = ({ title, status, description }: toastProps) => {
  toast({
    title: title,
    description: description,
    position: "top-right",
    status: status,
    duration: 2000,
    isClosable: true,
  });
};
