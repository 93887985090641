import { useEffect, useState } from "react";
import { MUITable } from "../../components/Table/MUITable";
import { InternalHeader } from "../../components/templates/InternalHeader";
import { api } from "../../services/api";
import { formatDateBrIndexWithOutHours } from "../../utils/formatDate";
import { formatMooney } from "../../utils/mooney";

interface CashierEntry {
  id: string;
  name: string;
  value: number;
  due_date: Date;
  client_payment_date?: Date;
  payment_date?: Date;
  status: string;
}

export function CashierEntriesList() {
  const [cashierEntries, setCashierEntries] = useState<CashierEntry[]>([]);
  useEffect(() => {
    api.get("/payments").then((response) => setCashierEntries(response.data));
  }, []);
  return (
    <>
      <InternalHeader title="Entradas" has_filter={false}></InternalHeader>
      <MUITable
        data={cashierEntries.map((cashierEntry) => [
          cashierEntry.id,
          cashierEntry.name,
          cashierEntry.value,
          cashierEntry.due_date,
          cashierEntry.payment_date,
          cashierEntry.status,
        ])}
        columns={[
          {
            name: "id",
            options: {
              display: "excluded",
              filter: false,
              sort: false,
            },
          },
          {
            name: "Empresa",
          },
          {
            name: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: number) => {
                return formatMooney(value);
              },
            },
          },
          {
            name: "Vencimento",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: Date) => {
                if (value) {
                  return formatDateBrIndexWithOutHours(value);
                }
                return "";
              },
            },
          },
          {
            name: "Pagamento",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: Date) => {
                if (value) {
                  return formatDateBrIndexWithOutHours(value);
                }
                return "";
              },
            },
          },
          {
            name: "Status",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: string) => {
                return value;
              },
            },
          },
        ]}
      />
    </>
  );
}
