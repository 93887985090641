import { SimpleGrid, Icon, Button } from "@chakra-ui/react";
import {
  FaBuilding,
  FaCoins,
  FaDollarSign,
  FaPercentage,
  FaCreditCard,
  FaMoneyBill,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { formatMooney } from "../../../utils/mooney";
import { ButtonListButton } from "../../organisms/ButtonListBox";
import { MUITable } from "../../Table/MUITable";

import { Box } from "../../../components/Widget/Box";

interface DefaultersTabPanelProps {
  defaulters: {
    companies: {
      company_name: string;
      total_payments: number;
      value_payments: number;
      credit_value: number;
      bank_splip_value: number;
      asaas_id: string;
      due_date: string;
    }[];
    total_payments: number;
    value_payments: number;
    credit_value: number;
    bank_splip_value: number;
    percentage_of_turnover: number;
  };
  has_mrr: boolean;
}

export function DefaultersTabPanel({
  defaulters,
  has_mrr,
}: DefaultersTabPanelProps) {
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
        <Box
          title="Empresas"
          value={defaulters.companies?.length ?? 0}
          icon={FaBuilding}
          color="white"
          bg="cyan.650"
        />
        <Box
          title="Cobranças"
          value={defaulters.total_payments ?? 0}
          icon={FaCoins}
          color="white"
          bg="gray.400"
        />
        <Box
          title="Total"
          value={formatMooney(defaulters.value_payments ?? 0)}
          icon={FaDollarSign}
          color="white"
          bg="green.600"
        />
        {has_mrr ? (
          <Box
            title="% Faturamento"
            value={defaulters.percentage_of_turnover}
            icon={FaPercentage}
            color="black"
            bg="orange.400"
          />
        ) : (
          <></>
        )}

        <Box
          title="Cartão"
          value={formatMooney(defaulters.credit_value) ?? 0}
          icon={FaCreditCard}
          color="black"
          bg="pink.500"
        />
        <Box
          title="Boleto"
          value={formatMooney(defaulters.bank_splip_value) ?? 0}
          icon={FaMoneyBill}
          color="white"
          bg="yellow.400"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1 }} spacing={2}>
        <MUITable
          data={defaulters.companies?.map((defaulter) => [
            defaulter.asaas_id,
            defaulter.company_name,
            defaulter.value_payments,
            defaulter.due_date,
            defaulter.total_payments,
          ])}
          columns={[
            {
              name: "id",
              options: {
                display: "excluded",
                filter: false,
                sort: false,
              },
            },
            {
              name: "Empresa",
            },
            {
              name: "Valor",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (value: number) => {
                  return formatMooney(value);
                },
              },
            },
            {
              name: "Data Prevista",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (value: Date) => {
                  return formatDateBrIndexWithOutHours(value);
                },
              },
            },
            {
              name: "Pagamentos",
            },
            {
              name: "Ações",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (dataIndex: any, rowIndex: any) => {
                  return (
                    <ButtonListButton>
                      <Button
                        mr={1}
                        as="a"
                        colorScheme="green"
                        target="_blank"
                        href={`https://www.asaas.com/customerAccount/show//${rowIndex.rowData[0]}`}
                      >
                        <Icon as={FaExternalLinkAlt} />
                      </Button>
                    </ButtonListButton>
                  );
                },
              },
            },
          ]}
        />
      </SimpleGrid>
    </>
  );
}
