import axios from "axios";

const user = localStorage.getItem("@B2S:user");
var parsedUser = "";
if (user) {
  parsedUser = JSON.parse(user).company_id;
}
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    company_id: parsedUser,
  },
});

api.interceptors.response.use(
  function successRequest(response) {
    return response;
  },
  async function errorRequest(error) {
    try {
      if (
        error.response.data.message === "Invalid JWT token" &&
        error.response.status === 401
      ) {
        window.dispatchEvent(new Event("logout"));

        return Promise.reject(error);
      }
      return Promise.reject(error);
    } catch {
      return Promise.reject(error);
    }
  }
);

export { api };
