import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, FormControl, Skeleton, Stack } from "@chakra-ui/react";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { FaCalendarDay } from "react-icons/fa";
import { FormControlSelect } from "../../../components/molecules/FormControl/FormControlSelect";

import { Input } from "../../../components/atoms/Input";
import { formatMooney } from "../../../utils/mooney";
import { formatDateBrWithOutHoursInStartDate } from "../../../utils/formatDate";

const editCategoryFormSchema = yup.object().shape({
  started_at: yup
    .string()
    .required("Data Inicial é obrigatório")
    .min(10, "A data deve conter no mínimo 3 caracteres")
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20|21|22|23)\d\d$/
    ),
  churn_at: yup
    .string()
    .required("Data do Churn é obrigatório")
    .min(10, "A data deve conter no mínimo 3 caracteres")
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20|21|22|23)\d\d$/
    ),
  value: yup.string().required("Valor é obrigatório"),
  reason: yup.string().required("Motivo é obrigatório"),
});

export function EditChurn() {
  const navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, reset, control, setValue } =
    useForm({
      resolver: yupResolver(editCategoryFormSchema),
    });

  useEffect(() => {
    api.get(`/churns/${id}`).then((response) => {
      reset({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
        started_at: formatDateBrWithOutHoursInStartDate(
          response.data.started_at
        ),
        churn_at: formatDateBrWithOutHoursInStartDate(response.data.churn_at),
      });
    });
    setLoading(true);
  }, [id, reset]);

  const handleEditCategory: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/churns/${id}`, {
          started_at: formValue.started_at,
          reason: formValue.reason,
          churn_at: formValue.churn_at,
          value: formValue.value.replace(".", "").replace(",", "."),
        });
        navigate("/churns");
        createToast({
          title: "Churn editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar o churn",
          status: "error",
        });
      }
    },
    [navigate, id]
  );

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#2b88a1"
          title="Editar Churn"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditCategory)}
              id="add-form"
            >
              <Stack spacing="4">
                <FormControl>
                  <Input
                    label="Data Início"
                    {...register("started_at")}
                    icon={FaCalendarDay}
                    isRequired={true}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => {
                      var value = e.target.value;
                      value = value.replace(/\D/g, "");
                      value = value.replace(/^(\d{2})(\d{2})/g, "$1/$2");
                      value = value.replace(/(\d)(\d{4})$/, "$1/$2");
                      setValue("started_at", value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    label="Data do Churn"
                    {...register("churn_at")}
                    icon={FaCalendarDay}
                    isRequired={true}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => {
                      var value = e.target.value;
                      value = value.replace(/\D/g, "");
                      value = value.replace(/^(\d{2})(\d{2})/g, "$1/$2");
                      value = value.replace(/(\d)(\d{4})$/, "$1/$2");
                      setValue("churn_at", value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    label="Valor"
                    error={formState.errors.value}
                    isRequired={true}
                    {...register("value")}
                    leftAddon="R$"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (value.length > 2) {
                        value = value
                          .replace(/\D+/g, "")
                          .replace(/([0-9]{2})$/g, ",$1");
                        e.target.value = value;
                        setValue("value", value);
                      }
                    }}
                  />
                </FormControl>
                <FormControlSelect
                  control={control}
                  error={formState.errors.reason}
                  label="Motivo"
                  {...register("reason")}
                  options={[
                    {
                      label: "Involuntário",
                      value: "Involuntário",
                    },
                    {
                      label: "Problemas financeiros",
                      value: "Problemas financeiros",
                    },
                    {
                      label: "Não enxergou valor / Não é prioridade",
                      value: "Não enxergou valor / Não é prioridade",
                    },
                    {
                      label: "Insatisfação com o produto",
                      value: "Insatisfação com o produto",
                    },
                    {
                      label: "Insatisfação com o atendimento / suporte",
                      value: "Insatisfação com o atendimento / suporte",
                    },
                    {
                      label: "Trocou por concorrente",
                      value: "Trocou por concorrente",
                    },
                    {
                      label: "Não é cliente fit",
                      value: "Não é cliente fit",
                    },
                  ]}
                  isRequired={true}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} />}
        />
      </Skeleton>
    </>
  );
}
