import { Control, Controller } from "react-hook-form";
import { SelectOptions } from "../../../atoms/Select";
import { FormControl } from "../../../FormControl";

interface IFormControlSelect {
  error?: any;
  control: Control<any, object>;
  options: {
    value: string;
    label: string;
  }[];
  name: string;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
}

export function FormControlSelect({
  name,
  label,
  error,
  control,
  options,
  isRequired = true,
  placeholder,
}: IFormControlSelect) {
  return (
    <FormControl
      name={name}
      label={label}
      isRequired={isRequired}
      error={error}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <SelectOptions {...field} placeholder={placeholder}>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </SelectOptions>
          );
        }}
      />
    </FormControl>
  );
}
