import { useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure,
  Heading,
  Text,
} from "@chakra-ui/react";
import { BsExclamationCircle } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { createToast } from "../Toast";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface DeleteModalProps {
  id: string;
  route: string;
  success_message: string;
  error_message: string;
  onDelete: (id: string) => void;
}

export function DeleteModal({
  route,
  id,
  error_message,
  success_message,
  onDelete,
}: DeleteModalProps) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleDelete = useCallback(async () => {
    api
      .delete(route + id)
      .then((response) => {
        onDelete!(id);
        createToast({
          title: success_message,
          status: "success",
        });
        onClose();
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
        createToast({
          title: error_message,
          status: "error",
        });
        onClose();
      });
  }, [route, id, onDelete, success_message, onClose, navigate, error_message]);

  return (
    <>
      <Button onClick={onOpen} colorScheme="red">
        <FaTrash />
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={undefined}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxW={{ base: "340px", md: "440px" }}>
          <AlertDialogHeader d="flex" flexDir="column" alignItems="center">
            <Icon
              as={BsExclamationCircle}
              fontSize="70"
              color="red.200"
              mb="10px"
            />
            <Heading textAlign="center">
              Deseja mesmo confirmar essa ação?
            </Heading>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text textAlign="center">Essa ação será irreversível</Text>
          </AlertDialogBody>
          <AlertDialogFooter d="flex" justifyContent="center">
            <Button
              colorScheme="red"
              h="46px"
              w="100px"
              fontSize="1.0625em"
              onClick={onClose}
            >
              Não
            </Button>
            <Button
              colorScheme="green"
              fontSize="1.0625em"
              h="46px"
              w="100px"
              ml={3}
              onClick={handleDelete}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
