import { useBreakpointValue } from "@chakra-ui/media-query";
import { Image, Flex } from "@chakra-ui/react";
import Logo from "../../../assets/logo.png";
import { SidebarBox } from "../../atoms/Box/SidebarBox";
import { InternalLink } from "../../atoms/Link/InternalLink";
import { MobileSidebar } from "../../organisms/Sidebar/MobileSidebar";
import { AdminSidebarNav } from "../AdminSidebarNav";

export function Sidebar() {
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false,
  });

  if (isDrawerSidebar) {
    return <MobileSidebar />;
  }
  return (
    <SidebarBox>
      <Flex maxW="178px" minH="79px" m="auto" align="center">
        <InternalLink link="/">
          <Image src={Logo} alt="Logo Falaê" align="center" mx="auto" my="2" />
        </InternalLink>
      </Flex>
      <AdminSidebarNav />
    </SidebarBox>
  );
}
