import { Button } from "@chakra-ui/react";
import { shade } from "polished";
interface AuthSubmitButtonProps {
  text: string;
  isLoading?: boolean;
}
export function AuthSubmitButton({ text, isLoading }: AuthSubmitButtonProps) {
  return (
    <Button
      type="submit"
      color="white"
      form="send-form"
      bg="#2b88a1"
      _hover={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
      _active={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
}
