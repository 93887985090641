import { Input, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaBuilding,
  FaDollarSign,
  FaMoneyBill,
  FaCalendarAlt,
  FaPercentage,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FormControl } from "../../../components/FormControl";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { PreLoader } from "../../../components/templates/PreLoader";
import { Box } from "../../../components/Widget/Box";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";
import { formatMooney } from "../../../utils/mooney";

interface Indicator {
  amount: number;
  quantity: number;
  ltv: number;
  percentage_of_mrr: number;
  average_ticket: number;
  average_time: number;
}

interface Selected {
  month: string;
  year: string;
}

export function ChurnsIndicators() {
  const query = useQuery();
  const [indicators, setIndicators] = useState<Indicator>({} as Indicator);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState<Selected>({
    month: query.get("month") ?? "",
    year: query.get("year") ?? "",
  } as Selected);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoaded(false);
    api
      .get(
        `/dashboards/churn/indicators?month=${selected.month}&year=${selected.year}`
      )
      .then((response) => {
        setIndicators(response.data);
        setIsLoaded(true);
      });
  }, [selected.month, selected.year]);

  return (
    <>
      <InternalHeader title="Indicadores - Churns" has_filter={true}>
        <FormControl name="month" label="Mês">
          <Input
            maxW="300px"
            type="month"
            defaultValue={`${selected.month}-${selected.year}`}
            onChange={(e) => {
              if (e.target.value.length === 7) {
                const [year, month] = e.target.value.split("-");
                setSelected({
                  ...selected,
                  month,
                  year,
                });
                navigate(
                  `/churns/indicadores/mensal?month=${month}&year=${year}`
                );
              }
            }}
          />
        </FormControl>
      </InternalHeader>
      <PreLoader message="Buscando indicadores" isLoaded={isLoaded}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
          <Box
            title="Empresas"
            value={indicators.quantity ?? 0}
            icon={FaBuilding}
            color="white"
            bg="blue.300"
          />
          <Box
            title="Faturamento"
            value={formatMooney(indicators.amount)}
            icon={FaDollarSign}
            color="white"
            bg="green.300"
          />
          <Box
            title="% do MRR"
            value={`${indicators.percentage_of_mrr ?? 0} %`}
            icon={FaPercentage}
            color="white"
            bg="orange.300"
          />
          <Box
            title="LTV"
            value={formatMooney(indicators.ltv)}
            icon={FaMoneyCheckAlt}
            color="white"
            bg="pink.300"
          />
          <Box
            title="Tempo médio(M)"
            value={`${indicators.average_time ?? 0} m`}
            icon={FaCalendarAlt}
            color="white"
            bg="red.300"
          />
          <Box
            title="Ticket Médio"
            value={formatMooney(indicators.average_ticket)}
            icon={FaMoneyBill}
            color="white"
            bg="yellow.400"
          />
        </SimpleGrid>
      </PreLoader>
    </>
  );
}
