import { FaSearch } from "react-icons/fa";
import { TableListButton } from "../../../atoms/Button/TableListButton";

interface SearchButtonProps {
  link: string;
}

export function SearchButton({ link }: SearchButtonProps) {
  return (
    <TableListButton color="white" link={link} bg="#a3a3a3" icon={FaSearch} />
  );
}
