import { Button, Icon } from "@chakra-ui/react";
import { shade } from "polished";
import { FaPlus } from "react-icons/fa";

interface ModalAddButtonProps {
  onClick: () => void;
  loading?: boolean;
}

export function ModalAddButton({ onClick, loading }: ModalAddButtonProps) {
  return (
    <Button
      _hover={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
      _active={{
        backgroundColor: shade(0.2, "#2b88a1"),
      }}
      mr="4px"
      color="white"
      onClick={onClick}
      bg="#2b88a1"
      isLoading={loading}
    >
      <Icon as={FaPlus} />
    </Button>
  );
}
