import { ReactNode } from "react";
import Logo from "../../assets/logo_vertical.png";
import BackgroundImage from "../../assets/login.png";
import { Flex, Box, Image } from "@chakra-ui/react";
interface AuthProps {
  children: ReactNode;
}
export function Auth({ children }: AuthProps) {
  return (
    <Flex minH="100vh" alignItems="stretch" bg="gray.100">
      <Flex
        flexDir="column"
        placeContent="center"
        align="center"
        width="100%"
        maxW="700"
      >
        <Flex flexDir="column" align="center" justify="center">
          <Image
            src={Logo}
            alt="Logo Falaê"
            maxW="250"
            maxH="170"
            loading="lazy"
            mb={10}
          />
          {children}
        </Flex>
      </Flex>
      <Box
        flex="1"
        bg={`url(${BackgroundImage}) no-repeat center`}
        bgSize="cover"
      ></Box>
    </Flex>
  );
}
