import { Icon, Button } from "@chakra-ui/react";
import { FaUndoAlt } from "react-icons/fa";

interface BackButtonProps {
  onClick: () => void;
}
export function BackButton({ onClick }: BackButtonProps) {
  return (
    <Button colorScheme="blue" variant="outline" onClick={onClick}>
      <Icon as={FaUndoAlt} mr="4px" />
      Voltar
    </Button>
  );
}
