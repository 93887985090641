import { useCallback, useEffect, useState } from "react";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { formatMooney } from "../../../utils/mooney";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { CopyButtonTableList } from "../../../components/organisms/CopyButtonTableList";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { PowerButtonList } from "../../../components/organisms/PowerButtonList";
import { createToast } from "../../../components/Feedback/Toast";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { useAuth } from "../../../hooks/auth";
import {
  formatDateBrIndexWithOutHours,
  monthDiff,
} from "../../../utils/formatDate";
import { formatPlanType } from "../../../utils/formatPlanType";
import { InternalHeader } from "../../../components/templates/InternalHeader";

interface Company {
  id: string;
  name: string;
  started_at?: Date;
  city: string;
  state: string;
  status: boolean;
  value: number;
  churn_at?: Date;
  is_plan: boolean;
  plan_type?: string;
}
export function ListCompaniesByFinanceCompany() {
  const { finance_company_id } = useParams();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { user } = useAuth();
  const handleUpdateStatus = useCallback(
    (id) => {
      api.patch(`/companies/status/${id}`).then((response) => {
        setCompanies(
          companies.map((company) => {
            if (company.id === id) {
              company.status = !company.status;
            }
            return company;
          })
        );
        createToast({
          title: "Empresa editada com sucesso!",
          status: "success",
        });
      });
    },
    [companies]
  );
  useEffect(() => {
    api
      .get(`/companies/financeCompanies/${finance_company_id}`)
      .then((response) => setCompanies(response.data))
      .catch((err: AxiosError) => {});
  }, [finance_company_id, navigate]);

  return (
    <>
      <InternalHeader title="Empresas - Financeiro" has_filter={false}>
        <AddButton link="/empresa/cadastrar" />
      </InternalHeader>

      <MUITable
        data={companies.map((company) => [
          company.id,
          company.name,
          company.city,
          company.state,
          company.is_plan,
          company.plan_type,
          company.started_at,
          company.status,
          company.value,
          company.started_at && company.is_plan
            ? monthDiff(
                new Date(company.started_at),
                company.churn_at ? new Date(company.churn_at) : new Date()
              )
            : "-",
        ])}
        columns={[
          {
            name: "id",
            options: {
              display: "excluded",
              filter: false,
              sort: false,
            },
          },
          {
            name: "Empresa",
          },
          {
            name: "Cidade",
            options: {
              display: "none",
            },
          },
          {
            name: "Estado",
            options: {
              display: "none",
            },
          },
          {
            name: "Plano",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: boolean) => {
                return value ? "Assinatura" : "Pontual";
              },
            },
          },
          {
            name: "Modelo do Plano",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: string) => {
                return value ? formatPlanType(value) : "";
              },
            },
          },
          {
            name: "Data Inicío",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value?: Date) => {
                if (value) {
                  return formatDateBrIndexWithOutHours(value);
                }
                return;
              },
            },
          },
          {
            name: "active",
            label: "Status",
            options: {
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return dataIndex ? "Ativa" : "Inativa";
              },
            },
          },
          {
            name: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: number) => {
                return formatMooney(value);
              },
            },
          },
          {
            name: "Tempo (m)",
          },
          {
            name: "Ações",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return (
                  <ButtonListButton>
                    <CopyButtonTableList
                      item={rowIndex.rowData[0]}
                      label="Copiar ID"
                    />
                    {user.is_admin === 0 && (
                      <>
                        <PowerButtonList
                          link="/empresas"
                          onClick={async () => {
                            handleUpdateStatus(rowIndex.rowData[0]);
                          }}
                          validCorrectButton={rowIndex.rowData[7]}
                        />
                        <EditButton
                          link={`/empresa/editar/${rowIndex.rowData[0]}`}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setCompanies(
                              companies.filter((company) => company.id !== id)
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="companies/"
                          error_message="Não foi possível remover a empresa"
                          success_message="Empresa removida com sucesso"
                        />
                      </>
                    )}
                  </ButtonListButton>
                );
              },
            },
          },
        ]}
      />
    </>
  );
}
