import { useCallback } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Flex, Select, SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { FormControl } from "../../../components/Form/FormControl";

const createUserFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref("password")], "As senhas precisam ser iguais"),
});

export function CreateUser() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { register, handleSubmit, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createUserFormSchema),
  });
  const handleCreateUser: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/users", {
          name: formValue.name,
          email: formValue.email,
          password: formValue.password,
          is_admin: formValue.is_admin,
        });
        navigate("/usuarios");

        createToast({
          title: "Usuário cadastrado com sucesso!",
          status: "success",
        });
      } catch {
        createToast({
          title: "Não foi possível cadastrar o usuário",
          status: "error",
        });
      }
    },
    [navigate]
  );

  const { errors } = formState;

  return (
    <>
      <Card
        border="#2b88a1"
        title="Cadastrar Usuário"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateUser)}
            id="add-form"
          >
            <Stack spacing="4">
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={["6", "8"]}
                w="100%"
              >
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <Input
                  type="email"
                  label="E-mail"
                  isRequired={true}
                  error={errors.email}
                  {...register("email")}
                />
                <Input
                  type="password"
                  label="Senha"
                  isRequired={true}
                  error={errors.password}
                  {...register("password")}
                />
                <Input
                  type="password"
                  label="Confirme a senha"
                  isRequired={true}
                  error={errors.password_confirmation}
                  {...register("password_confirmation")}
                />
                <FormControl
                  name="is_admin"
                  error={errors.is_admin}
                  label="Nível do usuário"
                  isRequired={true}
                >
                  <Controller
                    name="is_admin"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select {...field} placeholder="Selecione">
                          {user.is_admin === 0 && (
                            <>
                              <option value="0">Super Administrador</option>
                              <option value="1">Administrador</option>
                            </>
                          )}
                          <option value="2">Comercial</option>
                          <option value="3">Sucesso do Cliente</option>
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </SimpleGrid>
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
