import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import { ElementType, ReactNode } from "react";
import { SidebarIcon } from "../../../atoms/Icon/SidebarIcon";
import { StackSidebarSection } from "../../../atoms/Stack/StackSidebarSection";
import { SidebarText } from "../../../atoms/Text/SidebarText";

interface NavSectionProps {
  title: string;
  children: ReactNode;
  icon?: ElementType;
  tag?: string;
}
export function NavSection({ title, children, icon, tag }: NavSectionProps) {
  return (
    <AccordionItem my="2">
      <h2>
        <AccordionButton
          pb={2}
          px={1}
          _focus={{
            outline: "none",
          }}
          _expanded={{
            background: "#e0e0e0",
          }}
        >
          <Box flex="1" textAlign="left">
            <Flex align="center">
              {icon && <SidebarIcon icon={icon} />}
              <SidebarText text={title} />
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} bg="#f5f5f5">
        <StackSidebarSection>{children}</StackSidebarSection>
      </AccordionPanel>
    </AccordionItem>
  );
}
