import { Line } from "@ant-design/plots";

interface MultipleLineProps {
  data: {
    name: string;
    value: number;
    type: string;
  }[];
}

export const MultipleLine = ({ data }: MultipleLineProps) => {
  const config = {
    data,
    xField: "type",
    yField: "value",
    seriesField: "name",
    color: ["#53a846", "#dd4445", "#2b88a1"],
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  return <Line {...config} />;
};
