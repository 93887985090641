import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { formatMooney } from "../../../utils/mooney";
import { BackButton } from "../../../components/atoms/Button/BackButton";

interface Company {
  id: string;
  name: string;
  value: number;
}

export function ListResellerCompanies() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  useEffect(() => {
    api
      .get(`/companies/reseller/${id}`)
      .then((response) => setCompanies(response.data))
      .catch((err: AxiosError) => {});
  }, [id, navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Empresas do Revendedor"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <MUITable
            data={companies.map((company) => [
              company.id,
              company.name,
              company.value,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
