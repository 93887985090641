import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SidebarBoxProps {
  children: ReactNode;
}

export function SidebarBox({ children }: SidebarBoxProps) {
  return (
    <Box
      as="aside"
      w="64"
      mr="8"
      minH="100vh"
      position="fixed"
      top="0"
      overflow="hidden"
      bg="white"
      borderRight="1px"
      borderColor="gray.250"
      zIndex="11"
    >
      {children}
    </Box>
  );
}
