import { Button, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ElementType } from "react";
import { shade } from "polished";

interface TableListButtonProps {
  link: string;
  icon: ElementType;
  bg: string;
  color: string;
  onClick?: () => void;
  disabled?: boolean
}

export function TableListButton({
  link,
  bg,
  icon,
  color,
  onClick,
  disabled = false
}: TableListButtonProps) {
  return (
    <Button
      as={Link}
      to={link}
      bg={bg}
      color={color}
      onClick={onClick}
      _hover={{
        backgroundColor: shade(0.2, bg),
      }}
      _active={{
        backgroundColor: shade(0.2, bg),
      }}
      mr="4px"
      disabled={disabled}
    >
      <Icon as={icon} />
    </Button>
  );
}
