import { ReactNode } from "react";
import { FilterBox } from "../../atoms/Filters/Box";
import { FormControl } from "../../Form/FormControl";

interface FilterWIthFormControlProps {
  children: ReactNode;
  name: string;
  label: string;
}

export function FilterWIthFormControl({
  children,
  label,
  name,
}: FilterWIthFormControlProps) {
  return (
    <FilterBox>
      <FormControl name={name} label={label}>
        {children}
      </FormControl>
    </FilterBox>
  );
}
