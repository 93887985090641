import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Checkbox, Flex, Select, Stack } from "@chakra-ui/react";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { Input } from "../../../components/atoms/Input";
import { FormControl } from "../../../components/FormControl";
import { InternalHeader } from "../../../components/templates/InternalHeader";
interface Franchise {
  id: string;
  name: string;
}
const createCompanyFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  city: yup
    .string()
    .required("A cidade é obrigatória")
    .min(3, "A cidade deve conter no mínimo 3 caracteres"),
  state: yup
    .string()
    .required("O estado é obrigatória")
    .matches(/[a-zA-Z]{2}/gm, "Digite somente a sigla do Estado")
    .min(2, "O estado deve conter no mínimo 2 caracteres")
    .max(2, "O estado deve conter no mínimo 2 caracteres"),
  value: yup.string().required("O valor é obrigatório"),
});
interface Category {
  id: string;
  name: string;
}

interface Reseller {
  id: string;
  name: string;
}

interface FinanceCompany {
  id: string;
  name: string;
}
export function CreateCompany() {
  const navigate = useNavigate();
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [resellers, setResellers] = useState<Reseller[]>([]);
  const [financeCompanies, setFinanceCompanies] = useState<FinanceCompany[]>(
    []
  );

  const [selectedFranchise, setSelectedFranchise] = useState(false);
  useEffect(() => {
    api
      .get("/franchises")
      .then((response) => setFranchises(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
    api
      .get("/categories")
      .then((response) => setCategories(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
    api.get("/resellers").then((response) => setResellers(response.data));
    api
      .get("/financeCompanies")
      .then((response) => setFinanceCompanies(response.data));
  }, [navigate]);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createCompanyFormSchema),
  });
  const handleCreateCompany: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/companies", {
          name: formValue.name,
          city: formValue.city,
          state: formValue.state,
          franchise_id: formValue.franchise_id,
          value: formValue.value.replace(".", "").replace(",", "."),
          unique_payment: formValue.unique_payment,
          is_consultancy: formValue.is_consultancy,
          is_dispatch: formValue.is_dispatch,
          category_id: formValue.category_id,
          asaas_id: formValue.asaas_id,
          reseller_id: formValue.reseller_id,
          finance_company_id: formValue.finance_company_id,
          is_plan: formValue.is_plan ? true : false,
          ...(formValue.is_plan ? { plan_type: formValue.plan_type } : ""),
          responsible_name: formValue.responsible_name,
          responsible_email: formValue.responsible_email,
          responsible_phone: formValue.responsible_phone,
        });

        navigate("/empresas");
        createToast({
          title: "Empresa cadastrada com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível cadastrar a empresa",
          status: "error",
        });
      }
    },
    [navigate]
  );
  const handleGetCep = useCallback(
    (cep: string) => {
      axios
        .get(`https://viacep.com.br/ws/${cep.replace("-", "")}/json`)
        .then((response) => {
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
        });
    },
    [setValue]
  );

  const { errors } = formState;
  const watchIsPlan = watch("is_plan");
  return (
    <>
      <InternalHeader title="Cadastrar Empresa" has_filter={false}>
        <BackButton onClick={() => navigate(-1)} />
      </InternalHeader>
      <Card
        border="#2b88a1"
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateCompany)}
            id="add-form"
          >
            <Stack spacing="4">
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
              <FormControl
                name="reseller_id"
                isRequired={false}
                label="Revendedor"
                error={errors.reseller_id}
              >
                <Controller
                  control={control}
                  name="reseller_id"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione um Revendedor">
                      {resellers.map((reseller) => (
                        <option value={reseller.id}>{reseller.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                name="finance_company_id"
                isRequired={false}
                label="Empresa do Financeiro"
                error={errors.finance_company_id}
              >
                <Controller
                  control={control}
                  name="finance_company_id"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione uma empresa">
                      {financeCompanies.map((financeCompany) => (
                        <option value={financeCompany.id}>
                          {financeCompany.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                name="category_id"
                isRequired={true}
                label="Categoria"
                error={errors.category_id}
              >
                <Controller
                  control={control}
                  name="category_id"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione uma Categoria">
                      {categories.map((category) => (
                        <option value={category.id}>{category.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                name="franchise_id"
                isRequired={false}
                label="Rede"
                error={errors.franchise_id}
              >
                <Controller
                  control={control}
                  name="franchise_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Selecione uma franquia"
                      onChange={(e) => {
                        setSelectedFranchise(e.target.value ? true : false);
                        field.onChange(e);
                      }}
                    >
                      {franchises.map((franchise) => (
                        <option value={franchise.id}>{franchise.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {selectedFranchise && (
                <Controller
                  name="unique_payment"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      size="lg"
                      value={String(getValues("unique_payment"))}
                    >
                      Pagamento único na Rede
                    </Checkbox>
                  )}
                />
              )}
              <Controller
                name="is_plan"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    size="lg"
                    value={String(getValues("is_plan"))}
                  >
                    Plano
                  </Checkbox>
                )}
              />
              <Controller
                name="is_consultancy"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    size="lg"
                    value={String(getValues("is_consultancy"))}
                  >
                    Consultoria
                  </Checkbox>
                )}
              />
              <Controller
                name="is_dispatch"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    size="lg"
                    value={String(getValues("is_dispatch"))}
                  >
                    Disparo
                  </Checkbox>
                )}
              />

              <Input
                label="CEP"
                name="postalCode"
                maxLength={9}
                onChange={(e) => {
                  var value = e.target.value;
                  value = value
                    .replace(/\D+/g, "")
                    .replace(/(\d{5})(\d)/, "$1-$2");
                  e.target.value = value;
                }}
                onBlur={(e) => handleGetCep(e.target.value)}
              />
              <Input
                label="Cidade"
                isRequired={true}
                error={errors.city}
                {...register("city")}
              />
              <Input
                label="Estado"
                isRequired={true}
                error={errors.state}
                {...register("state")}
              />
              <Input
                label="ASAAS ID"
                isRequired={false}
                error={errors.asaas_id}
                {...register("asaas_id")}
              />
              {watchIsPlan && (
                <FormControl
                  name="plan_type"
                  isRequired={true}
                  label="Plano"
                  error={errors.plan_type}
                >
                  <Controller
                    control={control}
                    name="plan_type"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value="MONTHLY">Mensal</option>
                        <option value="QUARTERLY">Trimestral</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
              <Input
                label="Nome completo do responsável"
                isRequired={false}
                error={errors.responsible_name}
                {...register("responsible_name")}
              />
              <Input
                label={`E-mail`}
                isRequired={false}
                type="email"
                {...register("responsible_email")}
              />

              <Input
                label={`Celular`}
                isRequired={false}
                error={errors.responsible_phone}
                {...register("responsible_phone")}
                maxLength={15}
                onChange={(e) => {
                  var value = e.target.value;
                  value = value.replace(/\D/g, "");
                  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
                  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
                  e.target.value = value;
                  setValue("responsible_phone", value);
                }}
              />
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
