import { useCallback } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";

type FranchiseFormData = {
  name: string;
};

const createFranchiseFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

export function CreateFranchise() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm<FranchiseFormData>({
    resolver: yupResolver(createFranchiseFormSchema),
  });

  const handleCreateFranchise: SubmitHandler<FranchiseFormData> = useCallback(
    async (formValue) => {
      try {
        await api.post("/franchises", {
          name: formValue.name,
        });
        navigate("/redes");
        createToast({
          title: "Rede cadastrada com sucesso!",
          status: "success",
        });
      } catch {
        createToast({
          title: "Não foi possível cadastrar a rede",
          status: "error",
        });
      }
    },
    [navigate]
  );
  const { errors } = formState;

  return (
    <>
      <Card
        border="#2b88a1"
        title="Cadastrar Rede"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateFranchise)}
            id="add-form"
          >
            <Stack spacing="4" mb="4">
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
