import { Flex, Select, SimpleGrid } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  Controller,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../../components/atoms/Input";

import { Card } from "../../../components/Card";
import { createToast } from "../../../components/Feedback/Toast";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { api } from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { FormControl } from "../../../components/Form/FormControl";
import { formatMooney } from "../../../utils/mooney";
import { BackButton } from "../../../components/atoms/Button/BackButton";

const updatePlanFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

interface Plan {
  name: string;
  value: string;
  installments: string;
  payment_type: string;
  cycle?: string;
  plan_type?: string;
}

export function EditPlan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [plan, setPlan] = useState<Plan>({} as Plan);

  const [hasInstallments, setHasInstallments] = useState(false);

  const { register, handleSubmit, formState, setValue, control, reset } =
    useForm({
      defaultValues: {
        name: plan.name,
        value: plan.value,
        installments: plan.installments,
        payment_type: plan.payment_type,
        cycle: plan.cycle,
        plan_type: plan.plan_type,
      },
      resolver: yupResolver(updatePlanFormSchema),
    });

  const [hasCycle, setHasCycle] = useState(false);
  const handleUpdatePlan: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/plans/${id}`, {
          name: formValue.name,
          value: formValue.value,
          installments: formValue.installments,
          payment_type: formValue.payment_type,
          cycle: formValue.cycle,
          plan_type: formValue.plan_type,
        });

        navigate("/planos");
        createToast({
          title: "Planos editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar o plano",
          status: "error",
        });
      }
    },
    [id, navigate]
  );
  useEffect(() => {
    api.get(`/plans/${id}`).then((response) => {
      setPlan({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
      });
      reset({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
        discounted_installments: formatMooney(
          response.data.discounted_installments
        ).replace("R$ ", ""),
      });
      setHasCycle(plan.payment_type === "assinatura");
      setHasInstallments(plan.payment_type === "cobranca");
    });
  }, [id, plan.payment_type, reset]);
  const { errors } = formState;
  return (
    <>
      <InternalHeader title="Editar pagamentos" has_filter={false}>
        <BackButton onClick={() => navigate(-1)} />
      </InternalHeader>
      <Card
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleUpdatePlan)}
            id="add-form"
          >
            <SimpleGrid columns={{ base: 1 }} spacing={2}>
              <Input
                label="Nome"
                error={errors.name}
                isRequired={true}
                {...register("name")}
              />
              <FormControl
                name="payment_type"
                isRequired={true}
                label="Tipo de Pagamento"
                error={errors.payment_type}
              >
                <Controller
                  control={control}
                  name="payment_type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Selecione"
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.value === "assinatura") {
                          setHasCycle(true);
                          setHasInstallments(false);
                          setValue("installments", "");
                        } else {
                          if (e.target.value === "cobranca") {
                            setHasInstallments(true);
                            setHasCycle(false);
                            setValue("cycle", "");
                          } else {
                            setHasCycle(false);
                            setValue("cycle", "");
                            setHasInstallments(false);
                            setValue("installments", "");
                          }
                        }
                      }}
                    >
                      <option value="assinatura">Assinatura</option>
                      <option value="cobranca">Cobrança</option>
                    </Select>
                  )}
                />
              </FormControl>
              {hasCycle && (
                <FormControl
                  name="cycle"
                  isRequired={true}
                  label="Ciclo do Pagamento"
                  error={errors.cycle}
                >
                  <Controller
                    control={control}
                    name="cycle"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value="MONTHLY">Mensal</option>
                        <option value="QUARTERLY">Trimestral</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {hasCycle && (
                <FormControl
                  name="plan_type"
                  isRequired={true}
                  label="Plano"
                  error={errors.plan_type}
                >
                  <Controller
                    control={control}
                    name="plan_type"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value="MONTHLY">Mensal</option>
                        <option value="QUARTERLY">Trimestral</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {hasInstallments && (
                <FormControl
                  name="installments"
                  isRequired={true}
                  label="Parcelas"
                  error={errors.installments}
                >
                  <Controller
                    control={control}
                    name="installments"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value={1}>À vista</option>
                        <option value={2}>2x</option>
                        <option value={3}>3x</option>
                        <option value={4}>4x</option>
                        <option value={5}>5x</option>
                        <option value={6}>6x</option>
                        <option value={7}>7x</option>
                        <option value={8}>8x</option>
                        <option value={9}>9x</option>
                        <option value={10}>10x</option>
                        <option value={11}>11x</option>
                        <option value={12}>12x</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}

              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
            </SimpleGrid>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
