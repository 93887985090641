import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { formatMooney } from "../../../utils/mooney";
interface Transfer {
  id: string;
  value: number;
  created_at: Date;
  status: string;
  effective_date?: Date;
  schedule_date: Date;
}
export function ListTransfers() {
  const navigate = useNavigate();
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  useEffect(() => {
    api
      .get("/transfers")
      .then((response) => setTransfers(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Transferências"
        header={<></>}
        body={
          <MUITable
            data={transfers.map((transfer) => [
              transfer.id,
              transfer.created_at,
              transfer.value,
              transfer.status,
              transfer.schedule_date,
              transfer.effective_date,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Cadastro",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Status",
              },

              {
                name: "Agendamento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
              {
                name: "Realizado",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value?: Date) => {
                    return value ? formatDateBrIndexWithOutHours(value) : "";
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
