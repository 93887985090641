import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ButtonListButtonProps {
  children: ReactNode;
}

export function ButtonListButton({ children }: ButtonListButtonProps) {
  return (
    <Box whiteSpace="nowrap" d="inline-block">
      {children}
    </Box>
  );
}
