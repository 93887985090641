import { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { MUITable } from "../../../components/Table/MUITable";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { ModalBillsToPay } from "../../../components/templates/ModalBillsToPay";
import { api } from "../../../services/api";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { formatMooney } from "../../../utils/mooney";

interface BillToPay {
  id: string;
  name: string;
  value: number;
  date: string;
}
export function CashierOutletsList() {
  const [billsToPay, setBillsToPay] = useState<BillToPay[]>([]);

  useEffect(() => {
    api.get("/billsToPay").then((response) => setBillsToPay(response.data));
  }, []);
  return (
    <>
      <InternalHeader title="Saídas" has_filter={false}>
        <ModalBillsToPay
          icon={FaPlusCircle}
          label="Adicionar"
          title="Tipo de Cadastro"
          text=""
          firstOptionLink="/cadastro/saidas/ofx"
          firstOptionName="Arquivo OFX"
          secondOptionLink="/cadastro/saidas"
          secondOptionName="Padrão"
        />
      </InternalHeader>
      <MUITable
        data={billsToPay}
        columns={[
          {
            name: "id",
            options: {
              display: "excluded",
              filter: false,
              sort: false,
            },
          },
          {
            name: "name",
            label: "Nome",
          },
          {
            name: "value",
            label: "Valor",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: number) => {
                return formatMooney(value);
              },
            },
          },
          {
            name: "date",
            label: "Data",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: Date) => {
                return formatDateBrIndexWithOutHours(value);
              },
            },
          },
          {
            name: "Ações",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (dataIndex: any, rowIndex: any) => {
                return (
                  <ButtonListButton>
                    <EditButton link={`/editar/saida/${rowIndex.rowData[0]}`} />
                    <DeleteModal
                      onDelete={(id) => {
                        setBillsToPay(
                          billsToPay.filter((billToPay) => billToPay.id !== id)
                        );
                      }}
                      id={rowIndex.rowData[0]}
                      route="billsToPay/"
                      error_message="Não foi possível remover a saída"
                      success_message="Saída removida com sucesso"
                    />
                  </ButtonListButton>
                );
              },
            },
          },
        ]}
      />
    </>
  );
}
