import { FormState, FieldValues } from "react-hook-form";
import { Button } from "@chakra-ui/react";
interface SubmitButtonProps {
  text?: string;
  formState?: FormState<FieldValues>;
  isLoading?: boolean;
  loadingText?: string;
  form?: string;
  mt?: string;
}
export function SubmitButton({
  formState,
  text = "Cadastrar",
  isLoading,
  loadingText,
  form = "add-form",
  mt = "6",
}: SubmitButtonProps) {
  return (
    <Button
      form={form}
      type="submit"
      mt={mt}
      colorScheme="blue"
      fontWeight="normal"
      isLoading={isLoading}
      loadingText={loadingText}
    >
      {text}
    </Button>
  );
}
