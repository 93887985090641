import { useEffect, useState } from "react";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { Card } from "../../../components/Card";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { MUITable } from "../../../components/Table/MUITable";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { formatMooney } from "../../../utils/mooney";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { formatPlanType } from "../../../utils/formatPlanType";

interface Plan {
  id: string;
  name: string;
  value: number;
  installments: number;
  payment_type: string;
  cycle?: string;
  plan_type?: string;
}

export function ListPlans() {
  const { user } = useAuth();
  const [plans, setPlans] = useState<Plan[]>([]);
  useEffect(() => {
    api.get("/plans").then((response) => {
      setPlans(response.data);
    });
  }, []);
  return (
    <>
      <Card
        title="Planos"
        header={
          user.is_admin <= 2 ? <AddButton link="/plano/cadastrar" /> : <></>
        }
        body={
          <MUITable
            data={plans.map((plan) => [
              plan.id,
              plan.name,
              plan.value,
              plan.payment_type,
              plan.cycle,
              plan.plan_type,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Nome",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Tipo",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: string) => {
                    return value === "assinatura" ? "Assinatura" : "Cobrança";
                  },
                },
              },
              {
                name: "Ciclo de Pagamento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: string) => {
                    return formatPlanType(value);
                  },
                },
              },
              {
                name: "Duração",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: string) => {
                    return formatPlanType(value);
                  },
                },
              },
              {
                label: "Ações",
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        {user.is_admin <= 2 ? (
                          <EditButton
                            link={`/plano/editar/${rowIndex.rowData[0]}`}
                          />
                        ) : (
                          <></>
                        )}

                        {user.is_admin === 0 && (
                          <DeleteModal
                            onDelete={(id) => {
                              setPlans(plans.filter((plan) => plan.id !== id));
                            }}
                            id={rowIndex.rowData[0]}
                            route="plans/"
                            error_message="Não foi possível remover o Link de pagamento"
                            success_message="Link de pagamento removido com sucesso"
                          />
                        )}
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
