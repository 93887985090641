import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { MUITable } from "../../../components/Table/MUITable";
import { api } from "../../../services/api";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useNavigate } from "react-router-dom";
import { formatMooney } from "../../../utils/mooney";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";

interface Downsell {
  id: string;
  value: number;
  company: {
    name: string;
  };
  created_at: Date;
}

export function ListDownsells() {
  const navigate = useNavigate();
  const [downsells, setDownsells] = useState<Downsell[]>([]);
  useEffect(() => {
    api
      .get("downsells")
      .then((response) => setDownsells(response.data))
      .catch((err: AxiosError) => {});
  }, [navigate]);
  return (
    <>
      <Card
        border="#2b88a1"
        title="Downsells"
        header={<></>}
        body={
          <MUITable
            data={downsells.map((upsell) => [
              upsell.id,
              upsell.company.name,
              upsell.value,
              upsell.created_at,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Empresa",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },

              {
                name: "Data",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: Date) => {
                    return formatDateBrIndexWithOutHours(value);
                  },
                },
              },
              {
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        <DeleteModal
                          onDelete={(id) => {
                            setDownsells(
                              downsells.map((upsell) => {
                                if (upsell.id === id) {
                                  return {
                                    ...upsell,
                                    deleted_at: new Date(),
                                  };
                                }
                                return upsell;
                              })
                            );
                          }}
                          id={rowIndex.rowData[0]}
                          route="downsells/"
                          error_message="Não foi possível cancelar o downsell"
                          success_message="Downsell cancelado com sucesso"
                        />
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
