import { Button } from "@chakra-ui/button";
import { Tooltip } from "@chakra-ui/tooltip";
import { FaCopy } from "react-icons/fa";
import { createToast } from "../../Feedback/Toast";

interface CopyButtonTableListProps {
  item: string;
  label?: string;
}

export function CopyButtonTableList({
  item,
  label = "",
}: CopyButtonTableListProps) {
  function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.log(err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        createToast({
          title: "Item copiado com sucesso.",
          status: "success",
        });
      },
      function (err) {
        createToast({
          title: "Erro ao copiar item.",
          status: "error",
        });
      }
    );
  }
  return (
    <Tooltip hasArrow label={label}>
      <Button
        colorScheme="purple"
        mr="4px"
        onClick={() => {
          copyTextToClipboard(item);
        }}
      >
        <FaCopy />
      </Button>
    </Tooltip>
  );
}
