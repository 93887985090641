import ReactSelect from "react-select";
import { FilterWIthFormControl } from "../../../organisms/FilterWithFormControl";

interface StatusProps {
  onChange: (year: any) => void;
}

export function FilterStatus({ onChange }: StatusProps) {
  return (
    <FilterWIthFormControl name="year" label="Status">
      <ReactSelect
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        placeholder="Selecione o status da Empresa"
        onChange={(select: any) => {
          onChange(select);
        }}
        defaultValue={{ label: "Ativa / Inativa", value: "" }}
        options={[
          { label: "Ativa / Inativa", value: "" },
          { label: "Ativa", value: "true" },
          { label: "Inativa", value: "false" },
        ]}
      />
    </FilterWIthFormControl>
  );
}
