import React from "react";
import {
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

interface FooterProps {
  render: boolean;
}
function Footer({ render }: FooterProps) {
  return (
    <Box as="footer" role="contentinfo" ml={{ base: "0", lg: "64" }} mt="5">
      <Flex direction={{ base: "column", md: "row" }} align="center" bg="white">
        <Stack
          my={{ base: "6", md: 0 }}
          direction={{ base: "column", md: "row" }}
          marginStart={{ md: "8" }}
          fontSize="sm"
          spacing={{ base: "2", md: "8" }}
          textAlign={{ base: "center", md: "start" }}
        >
          <Text>&copy; {new Date().getFullYear()} B2S</Text>
        </Stack>
        <ButtonGroup
          marginStart={{ md: "auto" }}
          color="gray.600"
          variant="ghost"
        >
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/b2s-experi%C3%AAncia"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
            target="_blank"
          />
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/b2s-experi%C3%AAncia"
            aria-label="Youtube"
            icon={<FaYoutube />}
            target="_blank"
          />
          <IconButton
            as="a"
            href="https://www.instagram.com/experiencia.b2s/"
            aria-label="Intagram"
            icon={<FaInstagram />}
            target="_blank"
          />
          <IconButton
            as="a"
            href="https://www.facebook.com/experiencia.b2s/"
            aria-label="Facebook"
            icon={<FaFacebook />}
            target="_blank"
          />
        </ButtonGroup>
      </Flex>
    </Box>
  );
}

export default React.memo(Footer, (prev, next) => prev.render !== next.render);
