import {
  Tabs,
  TabList,
  Tab,
  Icon,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaChartBar, FaTable } from "react-icons/fa";

interface TableAndChartFranchiseDashboardsProps {
  chart: ReactNode;
  table: ReactNode;
}

export function TableAndChartFranchiseDashboards({
  chart,
  table,
}: TableAndChartFranchiseDashboardsProps) {
  return (
    <Tabs align="end" variant="enclosed" bg="white" isFitted>
      <TabList>
        <Tab>
          <Icon as={FaChartBar} />
        </Tab>
        <Tab>
          <Icon as={FaTable} />
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{chart}</TabPanel>
        <TabPanel>{table}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}
