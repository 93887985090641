export function formatDateBrIndex(date: Date | string) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}

export function formatDateBrIndexWithOutHours(date: Date | string) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}

export function transformStringInDate(date: string) {
  const [day, month, year] = date.split("/");
  return new Date(`${year}-${month}-${day}`);
}

export function monthDiff(d1: Date, d2: Date) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function formatDateBrWithOutHoursInStartDate(date: Date | string) {
  var formatDate;
  if (typeof date === typeof new Date()) {
    formatDate = date.toString().split("-");
  } else {
    formatDate = String(date).split("-");
  }
  if (date) {
    return `${formatDate[2]}/${formatDate[1]}/${formatDate[0]}`;
  } else {
    return;
  }
}

export function createDate(date: string) {
  return new Date(date.toString().replace("Z", "-03:00"));
}

export function formatDatabaseWithOutHours(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
}
