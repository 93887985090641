import { Heading, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaBoxes,
  FaDollarSign,
  FaHandshakeSlash,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import ColumnChart from "../../../components/Charts/Column";
import { FilterYear } from "../../../components/templates/Filters/Year";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { Box } from "../../../components/Widget/Box";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";
import { formatMooney } from "../../../utils/mooney";

interface Chart {
  box: {
    totalCompanies: number;
    units: number;
    total: number;
    ticket_company: number;
  };
  total: {
    type: string;
    value: number;
  }[];
  mooney: {
    type: string;
    value: number;
  }[];
  reason: {
    type: string;
    value: number;
  }[];
  states: {
    type: string;
    value: number;
  }[];
  categories: {
    type: string;
    value: number;
  }[];
  times: {
    type: string;
    value: number;
  }[];
}
interface Selected {
  year: string;
}
export function ChurnsDashboard() {
  const [results, setResults] = useState<Chart>({} as Chart);
  const navigate = useNavigate();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    year: query.get("year") ?? "",
  });
  useEffect(() => {
    api
      .get(`/dashboards/churns?year=${selected.year}`)
      .then((response) => setResults(response.data));
  }, [selected.year]);
  return (
    <>
      <InternalHeader title="Churns Dashboard" has_filter={true}>
        <FilterYear
          onChange={(year) => {
            if (year) {
              setSelected({
                ...selected,
                year: year.value,
              });
              navigate(`/dashboard/churns?year=${year.value}`);
            } else {
              setSelected({
                ...selected,
                year: "",
              });
              navigate(`/dashboard/churns?year=`);
            }
          }}
        />
      </InternalHeader>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
        {results.box && (
          <>
            <Box
              title="Empresas"
              value={results.box.totalCompanies ?? 0}
              icon={FaHandshakeSlash}
              color="white"
              bg="cyan.650"
            />
            <Box
              title="Unidades"
              value={results.box.units ?? 0}
              icon={FaBoxes}
              color="black"
              bg="yellow.450"
            />
            <Box
              title="Valor total"
              value={formatMooney(results.box.total)}
              icon={FaDollarSign}
              color="white"
              bg="green.300"
            />
            <Box
              title="Média"
              value={formatMooney(results.box.ticket_company)}
              icon={FaMoneyBillAlt}
              color="white"
              bg="purple.300"
            />
          </>
        )}
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Análise Mensal
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.total ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                ( R$ ) Análise Mensal
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.mooney ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Estado
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.states ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Tempo ( meses )
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.times ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Setor
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.categories ?? []}
              />
            </>
          }
        />
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <Heading fontSize="20px" textAlign="center">
                Mótivo
              </Heading>
              <ColumnChart
                yField="value"
                xField="type"
                data={results.reason ?? []}
              />
            </>
          }
        />
      </SimpleGrid>
    </>
  );
}
