import {
  Flex,
  Icon,
  IconButton,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { useSidebarDrawer } from "../../hooks/sidebarDrawer";
import { Profile } from "./Profile";
import Logo from "../../assets/logo.png";

export function Menu() {
  const { onOpen } = useSidebarDrawer();
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <Flex
      as="header"
      w="100%"
      h="20"
      mx="auto"
      px="6"
      align="center"
      bg="white"
      borderBottom="1px"
      borderColor="gray.250"
      position="fixed"
      top="0"
      zIndex="8"
    >
      {!isWideVersion ? (
        <IconButton
          aria-label="Open navitagion"
          icon={<Icon as={FaBars} />}
          fontSize="20"
          variant="unstyled"
          onClick={onOpen}
          mr="2"
        ></IconButton>
      ) : (
        <Image
          src={Logo}
          alt="Logo Experiência Falaê!"
          maxW="138px"
          align="center"
        />
      )}
      <Flex align="center" flex={1} justifyContent={"flex-end"} ml="80px">
        <Profile showProfileData={isWideVersion} />
      </Flex>
    </Flex>
  );
}
