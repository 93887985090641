import { Button, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { Card } from "../../../components/Card";
import { EditButton } from "../../../components/molecules/TableListButton/EditButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { MUITable } from "../../../components/Table/MUITable";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { formatMooney } from "../../../utils/mooney";
import { DeleteModal } from "../../../components/Feedback/DeleteModal";

interface PaymentLink {
  id: string;
  name: string;
  value: number;
  total_months_discounted_installments: number;
  discounted_installments: number;
  days_for_payment: number;
  months_to_first_payment: number;
}

export function ListPaymentLinks() {
  const { user } = useAuth();
  const [paymentLinks, setPaymentLinks] = useState<PaymentLink[]>([]);
  useEffect(() => {
    api.get("/paymentLinks").then((response) => {
      setPaymentLinks(response.data);
    });
  }, []);
  return (
    <>
      <Card
        title="Links de Pagamento"
        header={
          user.is_admin <= 2 ? (
            <AddButton link="/pagamento/link/cadastrar" />
          ) : (
            <></>
          )
        }
        body={
          <MUITable
            data={paymentLinks.map((paymentLink) => [
              paymentLink.id,
              paymentLink.name,
              paymentLink.value,
              paymentLink.discounted_installments,
              paymentLink.total_months_discounted_installments,
              paymentLink.months_to_first_payment,
            ])}
            columns={[
              {
                name: "id",
                options: {
                  display: "excluded",
                  filter: false,
                  sort: false,
                },
              },
              {
                name: "Nome",
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Desconto",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMooney(value);
                  },
                },
              },
              {
                name: "Meses de Desconto",
              },
              {
                name: "Meses para o primeiro pagamento",
              },
              {
                label: "Ações",
                name: "Ações",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (dataIndex: any, rowIndex: any) => {
                    return (
                      <ButtonListButton>
                        {user.is_admin <= 2 ? (
                          <EditButton
                            link={`/pagamento/link/editar/${rowIndex.rowData[0]}`}
                          />
                        ) : (
                          <></>
                        )}
                        <Button
                          mr={1}
                          as="a"
                          colorScheme="green"
                          target="_blank"
                          href={`${process.env.REACT_APP_WEB_URL}/cadastro/empresa/${rowIndex.rowData[0]}`}
                        >
                          <Icon as={FaExternalLinkAlt} />
                        </Button>
                        {user.is_admin === 0 && (
                          <DeleteModal
                            onDelete={(id) => {
                              setPaymentLinks(
                                paymentLinks.filter(
                                  (payment) => payment.id !== id
                                )
                              );
                            }}
                            id={rowIndex.rowData[0]}
                            route="paymentLinks/"
                            error_message="Não foi possível remover o Link de pagamento"
                            success_message="Link de pagamento removido com sucesso"
                          />
                        )}
                      </ButtonListButton>
                    );
                  },
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
