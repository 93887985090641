import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { createToast } from "../../../components/Feedback/Toast";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { api } from "../../../services/api";
import { useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";

const editCategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

interface Category {
  id: string;
  name: string;
}

export function EditCategory() {
  const navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState<Category>({} as Category);
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      name: category.name,
    },
    resolver: yupResolver(editCategoryFormSchema),
  });

  useEffect(() => {
    api.get(`/categories/${id}`).then((response) => {
      setCategory(response.data);
      reset(response.data);
    });
    setLoading(true);
  }, [id, reset]);

  const handleEditCategory: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/categories/${id}`, {
          name: formValue.name,
        });
        navigate("/categorias");
        createToast({
          title: "Categoria editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar a categoria",
          status: "error",
        });
      }
    },
    [navigate, id]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#2b88a1"
          title="Editar Categoria"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditCategory)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} />}
        />
      </Skeleton>
    </>
  );
}
