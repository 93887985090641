import { Flex, Select, SimpleGrid } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  Controller,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../../components/atoms/Input";

import { Card } from "../../../components/Card";
import { createToast } from "../../../components/Feedback/Toast";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { api } from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../components/Button/SubmitButton";
import { FormControl } from "../../../components/Form/FormControl";
import { formatMooney } from "../../../utils/mooney";
import { BackButton } from "../../../components/atoms/Button/BackButton";

const updatePaymentLinkFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

interface PaymentLink {
  name: string;
  description: string;
  billing_type: string;
  value: string;
  total_months_discounted_installments: number;
  discounted_installments: string;
  installments: string;
  payment_type: string;
  cycle?: string;
  reseller_id?: string;
  months_to_first_payment: number;
  plan_type?: string;
}

interface Reseller {
  id: string;
  name: string;
}
export function EditPaymentLink() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentLink, setPaymentLink] = useState<PaymentLink>(
    {} as PaymentLink
  );
  const [resellers, setResellers] = useState<Reseller[]>([]);

  const [hasInstallments, setHasInstallments] = useState(false);

  const { register, handleSubmit, formState, setValue, control, reset } =
    useForm({
      defaultValues: {
        name: paymentLink.name,
        description: paymentLink.description,
        billing_type: paymentLink.billing_type,
        value: paymentLink.value,
        total_months_discounted_installments:
          paymentLink.total_months_discounted_installments,
        discounted_installments: paymentLink.discounted_installments,
        installments: paymentLink.installments,
        payment_type: paymentLink.payment_type,
        cycle: paymentLink.cycle,
        plan_type: paymentLink.plan_type,
        reseller_id: paymentLink.reseller_id,
        months_to_first_payment: paymentLink.months_to_first_payment,
      },
      resolver: yupResolver(updatePaymentLinkFormSchema),
    });

  const [hasDiscount, setHasDiscount] = useState(false);
  const [hasCycle, setHasCycle] = useState(false);
  const handleUpdatePaymentLink: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/paymentLinks/${id}`, {
          name: formValue.name,
          description: formValue.description,
          billing_type: formValue.billing_type,
          value: formValue.value,
          total_months_discounted_installments:
            formValue.total_months_discounted_installments,
          discounted_installments:
            formValue.total_months_discounted_installments > 0
              ? formValue.discounted_installments
              : 0,
          installments: formValue.installments,
          payment_type: formValue.payment_type,
          reseller_id: formValue.reseller_id,
          cycle: formValue.cycle,
          plan_type: formValue.plan_type,
          months_to_first_payment: formValue.months_to_first_payment,
        });

        navigate("/links/pagamento");
        createToast({
          title: "Link do pagamento editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        createToast({
          title: "Não foi possível editar o link do pagamento",
          status: "error",
        });
      }
    },
    [id, navigate]
  );
  useEffect(() => {
    api.get(`/paymentLinks/${id}`).then((response) => {
      setPaymentLink({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
        discounted_installments: formatMooney(
          response.data.discounted_installments
        ).replace("R$ ", ""),
      });
      reset({
        ...response.data,
        value: formatMooney(response.data.value).replace("R$ ", ""),
        discounted_installments: formatMooney(
          response.data.discounted_installments
        ).replace("R$ ", ""),
      });
      setHasCycle(paymentLink.payment_type === "assinatura");
      setHasInstallments(paymentLink.payment_type === "cobranca");
      setHasDiscount(
        paymentLink.payment_type === "assinatura" &&
          Number(paymentLink.total_months_discounted_installments) > 0
      );
    });
    api.get("/resellers").then((response) => setResellers(response.data));
  }, [
    id,
    paymentLink.payment_type,
    paymentLink.total_months_discounted_installments,
    reset,
  ]);
  const { errors } = formState;
  return (
    <>
      <InternalHeader title="Editar pagamentos" has_filter={false}>
        <BackButton onClick={() => navigate(-1)} />
      </InternalHeader>
      <Card
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleUpdatePaymentLink)}
            id="add-form"
          >
            <SimpleGrid columns={{ base: 1 }} spacing={2}>
              <Input
                label="Nome"
                error={errors.name}
                isRequired={true}
                {...register("name")}
              />
              <FormControl
                name="reseller_id"
                isRequired={false}
                label="Revendedor"
                error={errors.reseller_id}
              >
                <Controller
                  control={control}
                  name="reseller_id"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione um Revendedor">
                      {resellers.map((reseller) => (
                        <option value={reseller.id}>{reseller.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <Input
                label="Descrição"
                error={errors.description}
                isRequired={true}
                {...register("description")}
              />
              <FormControl
                name="billing_type"
                isRequired={true}
                label="Forma de Pagamento"
                error={errors.billing_type}
              >
                <Controller
                  control={control}
                  name="billing_type"
                  render={({ field }) => (
                    <Select {...field} placeholder="Selecione">
                      <option value="BOLETO">Boleto</option>
                      <option value="CARTÃO">Cartão</option>
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl
                name="payment_type"
                isRequired={true}
                label="Tipo de Pagamento"
                error={errors.payment_type}
              >
                <Controller
                  control={control}
                  name="payment_type"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Selecione"
                      disabled
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.value === "assinatura") {
                          setHasCycle(true);
                          setHasInstallments(false);
                          setValue("installments", "");
                        } else {
                          if (e.target.value === "cobranca") {
                            setHasInstallments(true);
                            setValue("cycle", "");
                          } else {
                            setHasCycle(false);
                            setValue("cycle", "");
                            setHasInstallments(false);
                            setValue("installments", "");
                          }
                        }
                      }}
                    >
                      <option value="assinatura">Assinatura</option>
                      <option value="cobranca">Cobrança</option>
                    </Select>
                  )}
                />
              </FormControl>
              {hasCycle && (
                <FormControl
                  name="cycle"
                  isRequired={true}
                  label="Ciclo do Pagamento"
                  error={errors.cycle}
                >
                  <Controller
                    control={control}
                    name="cycle"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value="MONTHLY">Mensal</option>
                        <option value="QUARTERLY">Trimestral</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {hasCycle && (
                <FormControl
                  name="plan_type"
                  isRequired={true}
                  label="Plano"
                  error={errors.plan_type}
                >
                  <Controller
                    control={control}
                    name="plan_type"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value="MONTHLY">Mensal</option>
                        <option value="QUARTERLY">Trimestral</option>
                        <option value="SEMIANNUALLY">Semestral</option>
                        <option value="YEARLY">Anual</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {hasInstallments && (
                <FormControl
                  name="installments"
                  isRequired={true}
                  label="Parcelas"
                  error={errors.installments}
                >
                  <Controller
                    control={control}
                    name="installments"
                    render={({ field }) => (
                      <Select {...field} placeholder="Selecione">
                        <option value={1}>À vista</option>
                        <option value={2}>2x</option>
                        <option value={3}>3x</option>
                        <option value={4}>4x</option>
                        <option value={5}>5x</option>
                        <option value={6}>6x</option>
                        <option value={7}>7x</option>
                        <option value={8}>8x</option>
                        <option value={9}>9x</option>
                        <option value={10}>10x</option>
                        <option value={11}>11x</option>
                        <option value={12}>12x</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              {hasCycle && (
                <FormControl
                  name="total_months_discounted_installments"
                  isRequired={true}
                  label="Desconto"
                  error={errors.total_months_discounted_installments}
                >
                  <Controller
                    control={control}
                    name="total_months_discounted_installments"
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Selecione"
                        onChange={(e) => {
                          field.onChange(e);
                          if (Number(e.target.value) > 0) {
                            setHasDiscount(true);
                          } else {
                            setHasDiscount(false);
                            setValue("discounted_installments", "");
                          }
                        }}
                      >
                        <option value={0}>Sem desconto</option>
                        <option value={1}>Desconto de 1 mês</option>
                        <option value={2}>Desconto de 2 meses</option>
                        <option value={3}>Desconto de 3 meses</option>
                        <option value={4}>Desconto de 4 meses</option>
                        <option value={5}>Desconto de 5 meses</option>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
              <Input
                label="Meses para o primeiro pagamento"
                defaultValue={0}
                error={errors.months_to_first_payment}
                isRequired={true}
                min={0}
                type="number"
                {...register("months_to_first_payment")}
              />
              <Input
                label="Valor"
                error={errors.value}
                isRequired={true}
                {...register("value")}
                leftAddon="R$"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    e.target.value = value;
                    setValue("value", value);
                  }
                }}
              />
              {hasDiscount && (
                <Input
                  label="Parcela com desconto"
                  error={errors.discounted_installments}
                  isRequired={true}
                  {...register("discounted_installments")}
                  leftAddon="R$"
                  onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 2) {
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ",$1");
                      e.target.value = value;
                      setValue("discounted_installments", value);
                    }
                  }}
                />
              )}
            </SimpleGrid>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
