import { SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import ManyColumnType from "../../../components/Charts/ManyColumnType";
import { FilterYear } from "../../../components/templates/Filters/Year";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";

interface Selected {
  year: string;
}
interface BarProps {
  time: string;
  value: number;
  type: string;
}
export function ExpectedVersusReceivedDashboard() {
  const [data, setData] = useState<BarProps[]>([]);
  const navigate = useNavigate();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    year: query.get("year") ?? "",
  });

  useEffect(() => {
    api
      .get(`/dashboards/expected/received?year=${selected.year}`)
      .then((response) => {
        setData(response.data);
      });
  }, [selected.year]);
  return (
    <>
      <InternalHeader title="Recebidos X Previsto" has_filter={true}>
        <FilterYear
          onChange={(year) => {
            if (year) {
              setSelected({
                ...selected,
                year: year.value,
              });
              navigate(`/dashboard/previsto/recebido?year=${year.value}`);
            } else {
              setSelected({
                ...selected,
                year: "",
              });
              navigate(`/dashboard/previsto/recebido?year=`);
            }
          }}
        />
      </InternalHeader>
      <SimpleGrid columns={{ base: 1 }} spacing={2}>
        <Card
          border="#2b88a1"
          mb="20px"
          body={
            <>
              <ManyColumnType data={data} />
            </>
          }
        />
      </SimpleGrid>
    </>
  );
}
